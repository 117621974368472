import { CheckIcon } from "@heroicons/react/24/solid";
import React, { useEffect, useState } from "react";

export default function BeneficiosLista({ oferta, lista }) {
  const [titulosBeneficios, setTitulosBeneficios] = useState([]);
  useEffect(() => {
    if (oferta?.beneficiosPlano) {
      let arrBeneficios = oferta?.beneficiosPlano.split("|");
      let titulos = [];

      for (let i = 0; i < arrBeneficios.length; i += 2) {
        titulos.push(arrBeneficios[i]);
      }

      setTitulosBeneficios(titulos);
    }
  }, [oferta]);
  return (
    <div className="flex flex-col py-2 grow">
      {titulosBeneficios?.map((titulo) => (
        <p className="text-sm font-normal">
          <CheckIcon className="inline w-4 h-4 mr-2 text-primary-tim-light" />
          <span className="font-light">{titulo.trim()}</span>
        </p>
      ))}
    </div>
  );
}
