import { CheckBadgeIcon } from "@heroicons/react/24/solid";
import React from "react";

export default function BonusInternet({ oferta, textColor, lista, composicao, coresEqualizadas }) {
  if (lista) {
    return <BonusLista textColor={textColor} oferta={oferta} coresEqualizadas={coresEqualizadas} />;
  }
  if (composicao) {
    return <BonusComposicao textColor={textColor} oferta={oferta} coresEqualizadas={coresEqualizadas} />;
  }

  return (
    <>
      <BonusParagrafo textColor={textColor} oferta={oferta} coresEqualizadas={coresEqualizadas} />
    </>
  );
}
function BonusParagrafo({ oferta, textColor }) {
  return (
    <p className={`text-sm leading-[14px] ${textColor ? textColor : "text-gray-500"}`}>
      <span className="font-bold ">{oferta.dados} </span>do plano
      {oferta?.bonusPromo && (
        <>
          <span className="font-bold "> +{oferta.bonusPromo} </span>de bonus
        </>
      )}
      {oferta?.bonusC6 && (
        <>
          <span className="font-bold "> +{oferta.bonusC6} </span>de bônus C6 Bank
        </>
      )}
      {oferta?.bonus12M && (
        <>
          <span className="font-bold "> +{oferta.bonus12M} </span>bônus 12 meses
        </>
      )}
      {oferta?.bonusPortabilidade && (
        <>
          <span className="font-bold "> +{oferta.bonusPortabilidade} </span>bônus de portabilidade
        </>
      )}
    </p>
  );
}
function BonusLista({ oferta, coresEqualizadas }) {
  return (
    <div className="flex flex-col py-2 grow">
      <div>
        <span className="font-bold ">{oferta.dados} </span>do plano
      </div>

      {oferta?.bonusPromo && (
        <div>
          <span className="font-bold ">+{oferta?.bonusPromo}</span>
          <span className="font-light"> de bônus Promocional</span>
        </div>
      )}
      {oferta?.bonus12M && (
        <div>
          <span className={`font-bold ${!coresEqualizadas && "text-primary-tim-light"}`}>+{oferta?.bonus12M}</span>
          <span className="font-light"> de bônus 12 meses</span>
        </div>
      )}
      {oferta?.bonusC6 && (
        <div>
          <span className={`font-bold ${!coresEqualizadas && "text-primary-tim-light"}`}>+{oferta?.bonusC6}</span>
          <span className="font-light"> de bônus C6 Bank</span>
        </div>
      )}

      {oferta?.bonusPortabilidade && (
        <div>
          <span className={`font-bold ${!coresEqualizadas && "text-primary-tim-light"}`}>+{oferta?.bonusPortabilidade}</span>
          <span className="font-light"> bônus de portabilidade</span>
        </div>
      )}
    </div>
  );
}

function BonusComposicao({ oferta, textColor }) {
  return (
    <p className={`flex flex-col sm:flex-row text-sm leading-[14px] ${textColor ? textColor : "text-gray-500"}`}>
      <p className="whitespace-nowrap inline ml-2">
        <CheckBadgeIcon className="w-3 inline-block mb-1 mr-1" />
        <span className="font-bold ">{oferta?.dados} </span> do plano
      </p>
      {oferta?.bonusPortabilidade && (
        <>
          <p className="inline whitespace-nowrap font-bold">
            <span className="font-bold ml-2 whitespace-nowrap mr-1">
              <CheckBadgeIcon className="w-3 inline-block mb-1 mr-1" />
              {oferta.bonusPortabilidade}
            </span>
            bônus de portabilidade
          </p>
        </>
      )}
      {oferta?.bonusPromo && (
        <>
          <p className="inline whitespace-nowrap">
            <span className="font-bold ml-2 whitespace-nowrap mr-1">
              <CheckBadgeIcon className="w-3 inline-block mb-1 mr-1" />
              {oferta.bonusPromo}
            </span>
            de bonus
          </p>
        </>
      )}
      {oferta?.bonusC6 && (
        <>
          <p className="inline whitespace-nowrap">
            <span className="font-bold ml-2 whitespace-nowrap mr-1">
              <CheckBadgeIcon className="w-3 inline-block mb-1 mr-1" />
              {oferta.bonusC6}
            </span>
            de bônus C6 Bank
          </p>
        </>
      )}
      {oferta?.bonus12M && (
        <>
          <p className="inline whitespace-nowrap">
            <span className="font-bold ml-2 whitespace-nowrap mr-1">
              <CheckBadgeIcon className="w-3 inline-block mb-1 mr-1" />
              {oferta.bonus12M}
            </span>
            bônus 12 meses
          </p>
        </>
      )}
    </p>
  );
}
