const serverToken = process.env.REACT_APP_URL_TOKEN_DIGIPAY;
const serverDigipay = process.env.REACT_APP_ENPOINT_DIGIPAY;

export async function getToken() {
  const response = await fetch(serverToken + "/digipay/token", {
    headers: {
      "content-type": "application/json",
    },
  }).catch(() => {
    return null;
  });

  if (response?.body) {
    return await response.json();
  }

  return null;
}

export async function criaSessao({ token, cpf, ani, nome, email, plano, subOfferId, fluxo }) {
  //console.debug("sessao", { token, cpf, ani, nome, email, plano });

  if (!token) {
    return null;
  }

  const duration = 30;
  const payload = {
    channel: "BLACKROCK",
    client: {
      cpf: cpf.replace(/[.-]/gi, ""),
      name: nome,
      email: email || "",
    },
    msisdn: ani,
    plan: {
      id: plano,
      subOfferId,
    },
    flow: fluxo,
  };

  const response = await fetch(serverDigipay + `/v1/sessions?validation_policy=unlimited&duration=${duration}`, {
    method: "post",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  }).catch(() => {
    return null;
  });
  try {
    if (response?.body) {
      return await response.json();
    }
  } catch (e) {
    console.error("Erro criaSessao", e);
  }

  return null;
}
