export function trataRetornos(service, sessionToken, callback) {
  service.authorize(sessionToken, function (err, retornoSucesso) {
    console.log("err", err);
    console.log("service", service);

    let erro = "";

    if (err) {
      //Erros obtidos durante tentativa de autorização de pagamento
      if (err.name === service.ERROR_AUTHORIZATION_TYPE) {
        //É possível tratar o erro de acordo com os códigos específicos
        //acordados com a M4U, como por exemplo PAYMENT_STATUS_FAILED, BUSINESS_RULE_EXCEPTION
        //e INTERNAL_SERVER_ERROR
        if (err.detail?.reason === "PAYMENT_STATUS_FAILED") {
          //Tratar erro específico
          erro = "Pagamento com status inválido para autorização";
        } else if (err.detail?.reason === "BUSINESS_RULE_EXCEPTION") {
          //Erro em validacao de regra de negócio na Bemobi
          erro = "Falha na validação de regra.";
        } else if (err.detail?.reason === "INTERNAL_SERVER_ERROR") {
          //Erro interno na Bemobi, pode ser retentado
          erro = "Ocorreu um erro interno inesperado";
        }
      }
      //Erros obtidos durante tentativa de criação de ordem do lado da Bemobi
      if (err.name === service.ERROR_UNEXPECTED_TYPE) {
        //É possível tratar o erro de acordo com os códigos específicos
        //acordados com a M4U, como por exemplo BUSINESS_RULE_EXCEPTION e INTERNAL_SERVER_ERRO
        if (err.detail?.reason === "BUSINESS_RULE_EXCEPTION") {
          //Erro em validacao de regra de negócio na Bemobi
          erro = "Falha na validação de regra.";
        } else if (err.detail?.reason === "INTERNAL_SERVER_ERROR") {
          //Erro interno na Bemobi, pode ser retentado
          erro = "Ocorreu um erro interno inesperado";
        } else {
          erro = "Ocorreu um erro interno inesperado";
        }
      }
      //Erros obtidos pela lib instanciada ao tentar validar o sessionToken passado
      if (err.name === service.ERROR_SESSION_VALIDATION_TYPE) {
        //Ocorreu um erro para validar o token passado
        erro = "Ocorreu um erro interno inesperado";
      } else {
        //outros erros devem ser tratados de forma genérica
        erro = "Ocorreu um erro interno inesperado";
      }
      callback(erro);
      return;
    }

    //Resposta da autorização terá o body contendo o adesaoId
    // {
    //"adesaoId" : "token uuid"
    // }
    console.debug("retornoSucesso", retornoSucesso);
    callback(retornoSucesso, true);
  });
}
