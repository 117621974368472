import React, { useEffect, useState } from "react";
import { XMarkIcon } from "@heroicons/react/24/solid";
import { useCookies } from "react-cookie";

export default function Cookies({ exibir, onClose }) {
  const [show, setShow] = useState(false);
  const [efeito, setEfeito] = useState(false);
  const [cookies, setCookie] = useCookies(["telefoneCliente"]);

  const handleClose = (e) => {
    if (show) {
      setTimeout(() => {
        setShow(!show);
        onClose && onClose();
      }, 1000);

      setEfeito(false);
    }
  };

  const handleSalvarCookie = () => {
    setCookie("aceitaCookie", true, {
      path: "/",
      maxAge: process.env.REACT_APP_TEMPO_MAX_COOKIE || 172800,
    });

    handleClose();
  };

  useEffect(() => {
    setShow(!!exibir);

    if (exibir) {
      setTimeout(() => setEfeito(true), 100);
    }
  }, [exibir]);

  return (
    <>
      {show && (
        // Fundo escuro
        <div className="fixed top-0 items-end w-full h-screen bg-black bg-opacity-50">
          {/* Popup */}
          <div
            className={`fixed bottom-0 w-full p-6 pt-4 shadow-md transition-all duration-1000 ease-in-out text-white bg-base-tim-dark rounded-t-2xl ${
              efeito ? "translate-y-0 visible" : "translate-y-full invisible"
            }`}
          >
            <div className="flex flex-col">
              <div className="flex flex-row justify-end w-full">
                <span className="text-2xl cursor-pointer" onClick={handleClose}>
                  <XMarkIcon className="w-8" />
                </span>
              </div>
              <h3 className="text-xl font-extrabold">Cookies:</h3>
              <p className="mt-2 text-sm">
                A gente guarda estatisticas para melhorar sua experiencia de
                navegacao saiba mais em nossa{" "}
                <a
                  href="/politicas/politica-privacidade.pdf"
                  target="_blank"
                  className="text-amarelo-destaque"
                >
                  politica de privacidade
                </a>
                {" e "}
                <a
                  href="/politicas/politica-cookies.pdf"
                  target="_blank"
                  className="text-amarelo-destaque"
                >
                  politica de cookies
                </a>
                .
              </p>
              <div className="flex flex-col w-full md:items-end">
                <button
                  onClick={handleSalvarCookie}
                  className="w-full px-6 py-3 mt-4 text-xs font-extrabold text-white uppercase bg-transparent border border-white rounded-full md:w-auto"
                >
                  Entendi e fechar
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
