import { XMarkIcon } from "@heroicons/react/24/solid";
import React, { useState, useEffect } from "react";
import ImageApps from "./utils/imageApps/ImageApps";
import Img from "./utils/imageApps/Img";
import { QueueListIcon } from "@heroicons/react/24/outline";

export default function Beneficios({ exibir, onClose, oferta, extraClass, regulamento }) {
  const [show, setShow] = useState(false);
  const [efeito, setEfeito] = useState(false);
  const [titulosBeneficios, setTitulosBeneficios] = useState([]);
  const [descBeneficios, setDescBeneficios] = useState([]);

  const handleClose = () => {
    if (show) {
      setTimeout(() => {
        setShow(!show);
        onClose && onClose();
      }, 1000);
    }

    setEfeito(false);
  };

  useEffect(() => {
    setShow(!!exibir);

    if (exibir) {
      setTimeout(() => setEfeito(true), 100);
    }
  }, [exibir]);

  useEffect(() => {
    if (oferta?.beneficiosPlano) {
      let arrBeneficios = [];
      let titulos = [],
        desc = [];

      if (typeof oferta?.beneficiosPlano == "string") {
        arrBeneficios = oferta?.beneficiosPlano.split("|");
        for (let i = 0; i < arrBeneficios.length; i += 2) {
          titulos.push(arrBeneficios[i]);
          desc.push(arrBeneficios[i + 1]);
        }
      } else {
        arrBeneficios = oferta?.beneficiosPlano;
        for (let i = 0; i < arrBeneficios.length; i++) {
          const tempArray = arrBeneficios[i].split("|");
          titulos.push(tempArray[0]);
          desc.push(tempArray[1]);
        }
      }

      desc = desc.slice(0, titulos.length);
      setTitulosBeneficios(titulos);
      setDescBeneficios(desc);
    }
  }, [oferta]);

  return (
    <>
      {show && (
        // Fundo escuro
        <div className={`fixed min-h-screen w-full bg-black bg-opacity-50  top-0 z-40 ` + extraClass}>
          {/* Popup */}
          <div
            className={`flex flex-col p-6 mt-11 mr-8  z-50 bg-white rounded-r-lg transition-all duration-1000 ease-in-out md:mx-auto md:rounded-lg md:w-8/12 lg:w-6/12 xl:w-5/12 ${
              efeito ? "translate-x-0 visible" : "-translate-x-full invisible"
            }`}
          >
            <div className="flex flex-row items-center justify-between">
              <span className="px-4 py-2 font-bold border text-secondary-tim rounded-xl border-secondary-tim">+ Benefícios</span>
              <XMarkIcon className="w-6 cursor-pointer text-secondary-tim" onClick={handleClose} />
            </div>
            {/* Parceria */}
            {titulosBeneficios.map((titulo, index) => (
              <div className="mt-6 text-primary-tim-light">
                <h3 className="text-base font-bold">{titulo}</h3>
                <p className="text-xs">{descBeneficios[index]}</p>
              </div>
            ))}
            {/* Servicos */}
            {oferta?.servicosInclusos?.length > 0 && (
              <div className="mt-6">
                <h3 className="font-light text-md text-primary-tim-light">
                  Serviços <span className="font-bold">inclusos</span>
                </h3>
                <div className="mt-1 text-primary-tim-light">
                  {oferta?.servicosInclusos?.map((servico, index) => (
                    <h5 key={index + "-" + Date.now()} className="text-xs font-medium">
                      {servico}
                    </h5>
                  ))}
                </div>
              </div>
            )}

            {/* redes  */}
            {(oferta.deezer || oferta.apps) && (
              <div className="mt-6">
                <h3 className="font-light text-md text-primary-tim-light">
                  APPs <span className="font-bold">inclusos</span>
                </h3>
                {oferta.deezer && (
                  <div className="mb-4">
                    <Img app={"Deezer"} />
                  </div>
                )}
                <h5 className="text-sm font-light text-primary-tim-light">Sem descontar da internet</h5>
                <div className="flex flex-row">
                  <ImageApps oferta={oferta} />
                </div>
              </div>
            )}

            <p className="my-2 py-1 text-base">
              <a
                className="inline underline text-primary-tim-light"
                href={oferta.regulamento || `https://www.tim.com.br/rj/para-voce/controle/regulamentos`}
                rel="noreferrer"
                target="_blank"
              >
                Regulamento da oferta
              </a>
            </p>
          </div>
        </div>
      )}
    </>
  );
}
