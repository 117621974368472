export function validEmail(email) {
  const regexEmail = new RegExp(
    // eslint-disable-next-line no-control-regex
    "([!#-'*+/-9=?A-Z^-~-]+(.[!#-'*+/-9=?A-Z^-~-]+)*|\"([]!#-[^-~ \t]|(\\[\t -~]))+\")@([!#-'*+/-9=?A-Z^-~-]+(.[!#-'*+/-9=?A-Z^-~-]+)*|[[\t -Z^-~]*])",
    "ig"
  );
  return regexEmail.test(email);
}

export function formataTelefone(telefone) {
  if (!telefone) {
    return "";
  }

  telefone = telefone.replace(/([^0-9])/gi, "");
  return "(" + telefone.substring(0, 2) + ") " + telefone.substring(2, 7) + "-" + telefone.substring(7, telefone.length);
}

export function validaTelefone(telefone) {
  if (!telefone) {
    return false;
  }
  telefone = telefone.replace(/([( )-])/gi, "");

  const regexTelefone = new RegExp("^([14689][1-9]|2[12478]|3[1234578]|5[1345]|7[134579]) ?(9[0-9])[0-9]{3}-?[0-9]{4}$", "g");

  return regexTelefone.test(telefone);
}

export function formatarData(data, formatoSaida) {
  let retorno = null;
  if (data.includes("-")) {
    retorno = separaDiaMesAno(data, "-");
    if (!retorno) {
      return null;
    }
  } else if (data.includes("/")) {
    retorno = separaDiaMesAno(data, "/");
    if (!retorno) {
      return null;
    }
  }

  const { dia, mes, ano } = retorno;

  return formatoSaida.replace("DD", pad(dia, 2)).replace("MM", pad(mes, 2)).replace("YYYY", ano);
}

function separaDiaMesAno(data, caracter) {
  let dia, mes, ano;
  if (data.includes(caracter) && data.split(caracter)?.[0].length === 4) {
    dia = data.split(caracter)?.[2];
    mes = data.split(caracter)?.[1];
    ano = data.split(caracter)?.[0];
  } else if (data.split(caracter)?.[2].length === 4) {
    dia = data.split(caracter)?.[0];
    mes = data.split(caracter)?.[1];
    ano = data.split(caracter)?.[2];
  } else {
    return null;
  }

  return { dia, mes, ano };
}

export function pad(texto, qtdCaracteres) {
  const resto = qtdCaracteres - String(texto).length;
  return "0".repeat(resto > 0 ? resto : "0") + texto;
}

export function padR(texto, qtdCaracteres) {
  const resto = qtdCaracteres - String(texto).length;
  return texto + "0".repeat(resto > 0 ? resto : "0");
}

export function formataItemPrice(valor) {
  if (typeof valor === "number") {
    return valor;
  }
  if (!valor) return "";

  return valor.replace(".", "").replace(",", ".");
}

export function formataDataCampoTexto(texto) {
  if (!texto) {
    return "";
  }

  texto = texto.replace(/([^0-9])/gi, "");

  if (texto.length === 3) {
    return texto.substring(0, 2) + "/" + texto.substring(2, texto.length);
  }
  if (texto.length >= 5) {
    return texto.substring(0, 2) + "/" + texto.substring(2, 4) + "/" + texto.substring(4, texto.length);
  }
  // if (texto.length > 4) {
  //   return (
  //     texto.substring(0, 2) +
  //     "/" +
  //     texto.substring(2, 4) +
  //     "/" +
  //     texto.substring(4, texto.length)
  //   );
  // }
}

export function validaDataInput(data) {
  if (!data) {
    return false;
  }

  let dia = data.substring(0, 2);
  let mes = data.substring(3, 5);
  let ano = data.substring(6, data.length);

  if (ano.length < 4) {
    return false;
  }

  if (isNaN(dia) || isNaN(mes) || isNaN(ano)) {
    return false;
  }

  dia = parseInt(dia);
  mes = parseInt(mes);
  mes--;
  ano = parseInt(ano);

  if (mes < 0 || mes > 11) {
    return false;
  }

  if (dia < 1 || dia > 31 || (mes === 1 && dia > 29)) {
    return false;
  }

  return !!new Date(ano, mes, dia);
}

export function formataTimer(segundos) {
  var now = new Date().getTime();
  var countDownDate = new Date(now).setSeconds(new Date(now).getSeconds() + segundos);
  var distance = countDownDate - now;
  var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
  var seconds = Math.floor((distance % (1000 * 60)) / 1000);

  return `${minutes}:${pad(seconds, 2)}`;
}

export function formataEndereco(end, exibeCep) {
  let e = `${end.nomeRua}, ${end.numero}`;

  if (end.complemento) {
    e += `, ${end.complemento}`;
  }
  if (end.bairro) {
    e += `, ${end.bairro}`;
  }
  e += ` - ${end.cidade}/${end.estado}`;
  if (exibeCep) {
    e += ` - ${end.cep}`;
  }

  return e;
}

export function novoToken() {
  return Math.floor(10000 + Math.random() * 90000);
}

export function valorReais(number) {
  return new Intl.NumberFormat("pt-BR", { style: "currency", currency: "BRL", minimumFractionDigits: 2 }).format(number);
}
