import { Navigate, useSearchParams } from "react-router-dom";
import PagamentoBemobi from "./formaPagamento/PagamentoBemobi";
import { useRecoilState } from "recoil";
import { exibirDuvidasState } from "../../atoms/exibirDuvidasAtom";

export default function FecharPedido() {
  const [urlParams] = useSearchParams();
  const [, setExibirDuvidas] = useRecoilState(exibirDuvidasState);

  const ani = urlParams.get("ani");
  const pid = urlParams.get("pid");
  const oferta = urlParams.get("oferta");
  const nomeCliente = urlParams.get("nome");
  const cpf = urlParams.get("cpf");

  const medium = urlParams.get("utm_medium");
  const origem = urlParams.get("utm_source");

  if (medium === "express") {
    setExibirDuvidas(null);
    return (
      <div className="flex flex-col bg-white">
        <PagamentoBemobi origem={origem} pid={pid} ani={ani} dadosCliente={{ cpfCliente: cpf, nomeCliente }} idOferta={oferta} />
      </div>
    );
  }

  return <Navigate to={`/?${urlParams.toString()}`} />;
}
