import { PhoneIcon } from "@heroicons/react/24/solid";
import React from "react";
import { useState } from "react";

import { formataTelefone, validaTelefone } from "../utils/textUtils";

function PreencheAniRentabilizacao({ callback }) {
  const [ani, setAni] = useState("");
  const [erro, setErro] = useState(null);

  const handleContinuar = (e) => {
    e.preventDefault();
    if (!validaTelefone(ani)) {
      setErro("Informe um número de celular válido.");
      return;
    }

    callback(ani.replace(/([^0-9])/gi, ""));
  };

  return (
    <div className="fixed z-50 w-full h-screen bg-black bg-opacity-70 top-0">
      <div className="flex h-full flex-col items-center justify-center">
        <div className="mx-8 max-w-sm bg-white text-gray-800 rounded-lg p-8 px-12">
          <form className="" onSubmit={handleContinuar}>
            <h3 className="text-lg font-light mb-4">Informe o telefone para buscarmos o melhor plano </h3>
            <div className="flex flex-row justify-between w-full relatite ">
              <input
                name="ani"
                type="text"
                required
                value={ani}
                placeholder="(  )      -"
                onBlur={(e) => setAni(formataTelefone(e.target.value))}
                onChange={(e) => setAni(e.target.value)}
                className={`w-full border-b border-secondary-tim outline-none text-gray-600 font-medium `}
              />
              <PhoneIcon className="icon-input" />
            </div>
            {erro && <p className="text-sm font-medium text-red-400">{erro}</p>}
            <button type="submit" className="mt-4 px-8 w-full py-3 text-xs font-extrabold text-white uppercase rounded-full bg-secondary-tim">
              Continuar
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default PreencheAniRentabilizacao;
