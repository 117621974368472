import { Menu } from "@headlessui/react";
import { QuestionMarkCircleIcon } from "@heroicons/react/24/outline";

function PopupInfo({ mensagem }) {
  return (
    <Menu as="div" className="relative inline-block text-left mx-2">
      <Menu.Button>
        <QuestionMarkCircleIcon className="w-5 h-5" />
      </Menu.Button>

      <Menu.Items className="absolute right-0 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-none">
        <Menu.Item className=" ">
          <p className="z-[99] relative text-sm font-normal px-3 py-2 bg-slate-100">{mensagem}</p>
        </Menu.Item>
      </Menu.Items>
    </Menu>
  );
}

export default PopupInfo;
