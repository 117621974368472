export const DDDPorEstado = [
  //áíôã
  { sigla: "AC", nome: "Acre", ddds: ["68"] },
  { sigla: "AL", nome: "Alagoas", ddds: ["82"] },
  { sigla: "AM", nome: "Amazonas", ddds: ["92", "97"] },
  { sigla: "AP", nome: "Amapá", ddds: ["96"] },
  { sigla: "BA", nome: "Bahia", ddds: ["71", "73", "74", "75", "77"] },
  { sigla: "CE", nome: "Ceara", ddds: ["85", "88"] },
  { sigla: "DF", nome: "Distrito Federal", ddds: ["61"] },
  { sigla: "ES", nome: "Espírito Santo", ddds: ["27", "28"] },
  { sigla: "GO", nome: "Goias", ddds: ["61", "62", "64"] },
  { sigla: "MA", nome: "Maranhão", ddds: ["98", "99"] },
  { sigla: "MG", nome: "Minas Gerais", ddds: ["31", "32", "33", "34", "35", "37", "38"] },
  { sigla: "MS", nome: "Mato Grosso do Sul", ddds: ["67"] },
  { sigla: "MT", nome: "Mato Grosso", ddds: ["65", "66"] },
  { sigla: "PA", nome: "Pará", ddds: ["91", "93", "94"] },
  { sigla: "PB", nome: "Paraíba", ddds: ["83"] },
  { sigla: "PE", nome: "Pernambuco", ddds: ["81", "87"] },
  { sigla: "PI", nome: "Piauí", ddds: ["86", "89"] },
  { sigla: "PR", nome: "Parana", ddds: ["41", "42", "43", "44", "45", "46"] },
  { sigla: "RJ", nome: "Rio de Janeiro", ddds: ["21", "22", "24"] },
  { sigla: "RN", nome: "Rio Grande do Norte", ddds: ["84"] },
  { sigla: "RO", nome: "Rondônia", ddds: ["69"] },
  { sigla: "RR", nome: "Roraima", ddds: ["95"] },
  { sigla: "RS", nome: "Rio Grande do Sul", ddds: ["51", "53", "54", "55"] },
  { sigla: "SC", nome: "Santa Catarina", ddds: ["47", "48", "49"] },
  { sigla: "SE", nome: "Sergipe", ddds: ["79"] },
  { sigla: "SP", nome: "São Paulo", ddds: ["11", "12", "13", "14", "15", "16", "17", "18", "19"] },
  { sigla: "TO", nome: "Tocantins", ddds: ["63"] },
];

export const EstadoPorDDD = {
  11: "SP",
  12: "SP",
  13: "SP",
  14: "SP",
  15: "SP",
  16: "SP",
  17: "SP",
  18: "SP",
  19: "SP",
  21: "RJ",
  22: "RJ",
  24: "RJ",
  27: "ES",
  28: "ES",
  31: "MG",
  32: "MG",
  33: "MG",
  34: "MG",
  35: "MG",
  37: "MG",
  38: "MG",
  41: "PR",
  42: "PR",
  43: "PR",
  44: "PR",
  45: "PR",
  46: "PR",
  47: "SC",
  48: "SC",
  49: "SC",
  51: "RS",
  53: "RS",
  54: "RS",
  55: "RS",
  61: "DF",
  62: "GO",
  63: "TO",
  64: "GO",
  65: "MT",
  66: "MT",
  67: "MS",
  68: "AC",
  69: "RO",
  71: "BA",
  73: "BA",
  74: "BA",
  75: "BA",
  77: "BA",
  79: "SE",
  81: "PE",
  82: "AL",
  83: "PB",
  84: "RN",
  85: "CE",
  86: "PI",
  87: "PE",
  88: "CE",
  89: "PI",
  91: "PA",
  92: "AM",
  93: "PA",
  94: "PA",
  95: "RR",
  96: "AP",
  97: "AM",
  98: "MA",
  99: "MA",
};
