export function convertApiAntiga(ofertas) {
  return ofertas?.map((oferta) => {
    if (!oferta?.planoDestino?.beneficiosPlano) {
      return null;
    }

    const beneficios = oferta?.planoDestino.beneficiosPlano?.ItensBeneficio;
    return {
      ...oferta,
      deezer: oferta.deezer,
      dadosTotal: oferta.dadosTotal,
      beneficiosPlano: beneficios?.filter((b) => b.tipo === "beneficios").map((b) => b.item),
      regulamento: oferta.regulamento || "https://www.tim.com.br/rj/para-voce/controle/regulamentos",
      tipo: "Controle",
      ordemExibicao: 1,
      dados: oferta.dados,
      regional: oferta.regional,
      valor: oferta.valor,
      vas: "",
      express: oferta.express,
      planoDestinoExibicao: oferta.planoDestino.exibicao,
      bonusC6: oferta.bonus.find((b) => b.nomeReferencia.includes("C6"))?.valor,
      bonus12M: oferta.bonus.find((b) => b.nomeReferencia.includes("ESPECIAL 12M"))?.valor,
      bonusPromo: oferta.bonus.find((b) => b.nomeReferencia.includes("INTERNET 12M"))?.valor,
      bonusPortabilidade: oferta.bonus.find((b) => b.nomeReferencia.includes("PORTABILIDADE"))?.valor,
      appsPromoInfo: "",
      desc12M: oferta.desconto,
      fidelizado: oferta.fidelizado === "S",
      appsPromo: "",
      codigo: oferta.planoDestino.codigo,
      destaque: oferta.destaque,
      valorFinal: oferta.valorFinal,
      planoDestino: oferta.planoDestino.nome,
      apps: beneficios?.filter((b) => b.tipo === "trafegoIsento").map((b) => b.item),
      servicosInclusos: beneficios?.filter((b) => b.tipo === "svaIncluso").map((b) => b.item),
      objBonus: oferta.bonus,
      objBeneficios: oferta?.planoDestino.beneficiosPlano.ItensBeneficio,
    };
  });
}
