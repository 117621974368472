const serverLambda = process.env.REACT_APP_URL_API_LAMBDA_BR;
const serverApiViaCep = process.env.REACT_APP_API_CEP;
export async function consultaCEPMyTracking(cep) {
  const response = await fetch(serverLambda + "/consulta-cep-mytracking", {
    method: "post",
    body: JSON.stringify({ cep }),
    headers: {
      "content-type": "application/json",
      "x-api-key": process.env.REACT_APP_API_KEY_PORTAL,
    },
  }).catch((e) => {
    console.error(e);
    return null;
  });

  if (response?.body) {
    return await response.json();
  }

  return null;
}

export async function consultaCEPViaCep(cep) {
  const response = await fetch(serverApiViaCep + "/" + cep + "/json/").catch((e) => {
    console.error(e);
    return null;
  });

  if (response?.body) {
    return await response.json();
  }

  return null;
}
