import React from "react";

export default function ImgLimitado3m({ height = "h-6" }) {
  return (
    <div>
      <div className="flex flex-row items-center justify-center space-x-2 px-1 py-2 border border-secondary-tim rounded-xl">
        <img src={`/assets/Instagram.png`} alt={`Logo Instagram`} title={"Instagram"} className={`w-auto ${height}  text-white`} />
        <img src={`/assets/Facebook.png`} alt={`Logo Facebook`} title={"Facebook"} className={`w-auto ${height}  text-white`} />

        <img src={`/assets/Twitter.png`} alt={`Logo X`} title={"X"} className={`w-auto ${height}  text-white`} />
      </div>
      <p className="text-center text-xs">limitado a 3 meses</p>
    </div>
  );
}
