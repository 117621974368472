const server = process.env.REACT_APP_ENDPOINT_API;
//TODO
export async function enviaCodigoSms(ani) {
  const response = await fetch(server + "/check/gera-codigo", {
    method: "post",
    body: JSON.stringify({ ani, metodo: "sms" }),
    headers: {
      "content-type": "application/json",
      "x-api-key": process.env.REACT_APP_API_KEY_PORTAL,
    },
  }).catch(() => {
    return null;
  });

  if (response?.body) {
    return await response.json();
  }

  return null;
}
//TODO
export async function enviaCodigoWhatsapp(ani) {
  const response = await fetch(server + "/check/gera-codigo", {
    method: "post",
    body: JSON.stringify({ ani, metodo: "whatsapp" }),
    headers: {
      "content-type": "application/json",
      "x-api-key": process.env.REACT_APP_API_KEY_PORTAL,
    },
  }).catch(() => {
    return null;
  });

  if (response?.body) {
    return await response.json();
  }

  return null;
}

export async function validaCodigo(ani, codigo, metodo) {
  const response = await fetch(server + "/check/valida-codigo", {
    method: "post",
    body: JSON.stringify({ ani, metodo, codigo }),
    headers: {
      "content-type": "application/json",
      "x-api-key": process.env.REACT_APP_API_KEY_PORTAL,
    },
  }).catch(() => {
    return null;
  });

  if (response?.body) {
    return await response.json();
  }

  return null;
}
