import React, { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { Navigate } from "react-router";
import { useRecoilState } from "recoil";
import { processoState } from "../atoms/processAtom";
import Cookies from "../components/Cookies";
import Oferta from "../components/Oferta";
import Plano from "../components/Plano";
import { pushEventToDataLayer } from "../utils/gtm";
import { formataItemPrice } from "../utils/textUtils";
import { convertApiAntiga } from "../lib/migracao/deParaCamposOferta";

function ordenaPlanos(a, b) {
  return a.ordemExibicao - b.ordemExibicao;
}

export default function Planos() {
  const [Loading, setLoading] = useState(true);
  const [processo] = useRecoilState(processoState);
  const [ofertaPlanos, setOfertasPlanos] = useState([]);
  const [exibirCookies, setExibirCookies] = useState(false);

  const [cookies] = useCookies(["processo", "aceitaCookie"]);

  useEffect(() => {
    if (processo) {
      let p = processo.ofertas?.planos || [];
      if (processo.dadosOferta === true) {
        p = convertApiAntiga(p);
      }
      let pOrdenado = p.slice().sort(ordenaPlanos);
      setOfertasPlanos(pOrdenado);

      pushEventToDataLayer({
        event: "view_item_list",
        ecommerce: {
          items: [
            ...pOrdenado.map((oferta) => ({
              item_name: oferta?.planoDestinoExibicao,
              item_id: oferta?.planoDestinoExibicao + (oferta?.fidelizado ? "_f" : ""),
              item_price: formataItemPrice(oferta?.valorFinal),
              item_brand: oferta?.tipo,
              item_category: oferta?.destaque,
              item_variant: oferta?.beneficiosPlano,
              list: "vitrine de planos",
              index: oferta?.ordemExibicao,
            })),
          ],
        },
      });
    }
    setLoading(false);

    setTimeout(() => {
      if (!cookies?.aceitaCookie) {
        setExibirCookies(true);
      }
    }, 1000);
  }, [processo]);

  console.debug("OFERTAS");
  return (
    <>
      {!Loading && !processo?.telefoneCliente && !processo?.fluxo === "aquisicao" && !processo?.ddd && <Navigate replace to="/" />}
      {/* Cliente */}
      <Oferta exibeSaudacao={!!processo?.nomeCliente} nomeCliente={processo?.nomeCliente} />
      {/* Planos */}
      <div className="w-full bg-secondary-tim" id="div-planos">
        <div className="p-6 pl-0 controle-tamanho-tela">
          <div className="flex flex-row items-center justify-between pl-6">
            <div className="grow">
              <span className="px-4 py-2 font-bold text-white border border-white rounded-xl">Planos</span>
            </div>
          </div>
          {/* Lista planos */}
          <div
            id="div-planos"
            className="flex flex-row items-center w-screen pt-8 pb-2 pl-6 pr-12 space-x-4 overflow-x-auto overflow-y-hidden md:pr-4 md:w-auto scrollbar"
          >
            {ofertaPlanos.map((oferta, index) => {
              return (
                <Plano
                  key={"plano__" + oferta.ordemExibicao + "__" + index}
                  oferta={oferta}
                  melhorOferta={oferta.destaque}
                  telefoneCliente={processo?.telefoneCliente}
                />
              );
            })}
          </div>
        </div>
      </div>
      <Cookies exibir={exibirCookies} onClose={() => setExibirCookies(false)} />
    </>
  );
}
