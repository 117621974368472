import React, { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { processoState } from "../../atoms/processAtom";
import { formataTelefone, formataTimer } from "../../utils/textUtils";
import { enviaCodigoSms, enviaCodigoWhatsapp, validaCodigo } from "../../lib/api/confirmacaoTelefone";
import { ArrowDownCircleIcon, ArrowPathIcon } from "@heroicons/react/24/solid";

function geraArrayCodigo(qtd) {
  return new Array(qtd).fill("");
}

export default function ConfirmaTelefone({ tokenInicial, envioInicial, callbackValidado, onClose }) {
  const tempoPadraoReenvio = process.env.REACT_APP_TEMPO_REENVIO_CODIGO_SEGUNDOS;
  const qtdDigitosCodigo = 6;

  const [processo] = useRecoilState(processoState);
  const [tempoNovoToken, setTempoNovoToken] = useState(tempoPadraoReenvio);
  const [habilitaNovoToken, setHabilitaNovoToken] = useState(!tokenInicial);
  const [erroEnvio, setErroEnvio] = useState(null);
  const [erroCodigo, setErroCodigo] = useState(!tokenInicial ? "Ocorreu um erro selecione uma forma para receber o código de validação." : null);
  const [codigoSeguranca, setCodigoSeguranca] = useState("");
  const [metodoEnvio, setMetodoEnvio] = useState(envioInicial);
  const [loadingValidacao, setLoadingValidacao] = useState(false);

  useEffect(() => {
    if (tempoNovoToken !== null && tempoNovoToken >= 0) {
      setTimeout(() => {
        setTempoNovoToken(tempoNovoToken - 1);
      }, 1000);
    }

    if (tempoNovoToken < 0) {
      setTempoNovoToken(null);
      setHabilitaNovoToken(true);
      setErroEnvio("");
    }
  }, [tempoNovoToken]);

  const handleEnvioSms = async () => {
    setErroCodigo("");
    setErroEnvio("");
    setCodigoSeguranca("");

    const data = await enviaCodigoSms(processo?.telefoneCliente);
    if (!data?.gerado) {
      setErroEnvio("Erro ao enviar o código por SMS. Por favor, tente por outro meio.");
      return;
    }

    setTempoNovoToken(tempoPadraoReenvio);
    setHabilitaNovoToken(false);

    setMetodoEnvio("sms");
  };

  const handleEnvioWhatsapp = async () => {
    setErroCodigo("");
    setErroEnvio("");
    setCodigoSeguranca("");

    const data = await enviaCodigoWhatsapp(processo?.telefoneCliente);

    if (!data?.gerado) {
      setErroEnvio("Erro ao enviar o código por WhatsApp. Por favor, tente por outro meio.");
      return;
    }
    setTempoNovoToken(tempoPadraoReenvio);
    setHabilitaNovoToken(false);

    setMetodoEnvio("whatsapp");
  };

  const handleValidaCodigo = async (codigo) => {
    codigo = codigo || codigoSeguranca;
    if (codigo.length < qtdDigitosCodigo) {
      setErroCodigo(`O codigo deve ter ${qtdDigitosCodigo} dígitos.`);
      return;
    }

    setLoadingValidacao(true);
    setErroCodigo("");
    setErroEnvio("");
    const data = await validaCodigo(processo?.telefoneCliente, codigo, metodoEnvio);
    setLoadingValidacao(false);
    if (!data.validado) {
      setErroCodigo("Erro validar o código, por favor, tente novamente.");
      setHabilitaNovoToken(true);
      return;
    }

    callbackValidado();
  };

  const handleChangeInput = (e) => {
    const value = e.target.value;

    setCodigoSeguranca(value);
    if (value.length === qtdDigitosCodigo) {
      handleValidaCodigo(value);
    }
  };
  return (
    <div className="fixed top-0 z-50 w-full h-screen bg-black bg-opacity-70">
      <div className="flex flex-col items-center justify-center h-full">
        <div className="max-w-md p-8 px-12 mx-8 text-gray-800 bg-white rounded-lg">
          <form className=" text-primary-tim-light">
            <h3 className="mb-4 text-base font-light">
              Para sua segurança e para confirmar seu pedido, preencha o código de segurança que enviamos por SMS para o número{" "}
              <span className="whitespace-nowrap">{formataTelefone(processo?.telefoneCliente)}</span>.
            </h3>
            <div className="flex flex-col items-center justify-center">
              <div className="flex flex-col items-center justify-center">
                {(erroEnvio || erroCodigo) && <h3 className="font-medium text-sm text-red-400">{erroEnvio || erroCodigo}</h3>}

                <>
                  <h4 className="text-sm font-medium">Código de segurança</h4>
                  <div className="flex flex-col mt-1 ">
                    <input
                      type="text"
                      maxLength={qtdDigitosCodigo}
                      value={codigoSeguranca}
                      autoComplete="off"
                      onFocus={(e) => e.target.select()}
                      // disabled={habilitaNovoToken}
                      onChange={handleChangeInput}
                      name={"codigo_seguranca"}
                      key={"input_codigo_seguranca"}
                      id={"codigo_seguranca"}
                      className="tracking-[1.3em] py-2 pl-[1.3em] text-center border rounded-md w-52 border-primary-tim-light disabled:border-gray-400"
                    />
                  </div>
                  <button
                    type="button"
                    disabled={loadingValidacao}
                    onClick={handleValidaCodigo}
                    className=" rounded-full w-40 border text-center border-verde-tim-dark px-4 py-2 text-xs font-extrabold uppercase bg-white text-verde-tim-dark mt-2 hover:bg-verde-tim-dark focus:text-white focus:bg-verde-tim-dark hover:text-white disabled:text-gray-200 disabled:border-gray-200 disabled:hover:bg-white"
                  >
                    {loadingValidacao ? <ArrowPathIcon className="w-5 h-5 mx-auto animate-spin" /> : "Validar código"}
                  </button>
                </>
              </div>

              {tempoNovoToken !== null && (
                <div className="my-4">
                  <span className="text-sm text-emerald-600">Outro código poderá ser gerado em {formataTimer(tempoNovoToken)}</span>
                </div>
              )}
              {habilitaNovoToken && (
                <div className="flex flex-wrap items-center justify-center py-2 gap-2">
                  <button
                    type="button"
                    onClick={handleEnvioSms}
                    className="w-auto px-1 py-2 text-xs font-extrabold underline uppercase bg-white text-secondary-tim "
                  >
                    Reenviar SMS
                  </button>
                  <button
                    type="button"
                    onClick={handleEnvioWhatsapp}
                    className="w-auto px-1 py-2 text-xs font-extrabold underline uppercase bg-white text-verde-tim-dark "
                  >
                    Enviar por WhatsApp
                  </button>
                </div>
              )}
              <div>
                <button onClick={onClose}>Voltar</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
