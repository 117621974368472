import { padR } from "./textUtils";

export const getNumDados = (dadosTotal) => {
  if (!dadosTotal) return;
  return dadosTotal.substring(0, dadosTotal.length - 2);
};
export const getMedidaDados = (dadosTotal) => {
  if (!dadosTotal) return;
  return dadosTotal.substring(dadosTotal.length - 2);
};

export const getDezenasValor = (valorTotal) => {
  if (!valorTotal) return;

  const separador = typeof valorTotal === "string" ? "," : ".";
  valorTotal = valorTotal + "";
  return valorTotal.split(separador)[0];
};

export const getCentavosValor = (valorTotal) => {
  if (!valorTotal) return;

  const separador = typeof valorTotal === "string" ? "," : ".";
  valorTotal = valorTotal + "";

  const centavos = valorTotal.split(separador)[1]?.substring(0, 2);

  return centavos ? padR(centavos, 2) : "00";
};
