import React, { useEffect, useState } from "react";

export default function CustomInput({ lable, icon, invalidText, validFx, formatFx, fxOnChange, required, maxLength, ...props }) {
  const [campoNecessario, setCampoNecessario] = useState(false);
  const [valorInvalido, setValorInvalido] = useState(false);
  const [text, setText] = useState("");
  const { onBlur, onChange, value } = props;

  const handleBlurEvent = (e) => {
    const valorDigitado = e.target.value;
    if (required && valorDigitado === "") {
      setCampoNecessario(true);
    } else {
      setCampoNecessario(false);
    }

    let digitadoValido = true;
    if (validFx) {
      digitadoValido = validFx(valorDigitado);
    }
    setValorInvalido(!digitadoValido);

    if (digitadoValido) {
      formatFx && setText(formatFx(valorDigitado));
    }

    onBlur && onBlur(e);
  };

  const handleChange = (e) => {
    let invalido = false;
    if (validFx && !validFx(e.target.value)) {
      invalido = true;
    }

    if (!fxOnChange) {
      setText(e.target.value);
    } else {
      setText(formatFx(e.target.value));
    }
    if (invalido === false) {
      onChange && onChange(e);
    } else {
      onChange && onChange({ target: { name: e.target.name, value: "" } });
    }
  };

  useEffect(() => {
    let v = value;
    if (formatFx) {
      v = formatFx(v);
    }
    setText(v);
  }, [value]);

  return (
    <div>
      {lable && <label className={`text-sm font-bold ${!campoNecessario ? "text-secondary-tim" : "text-vermelho-erro"}`}>{lable}</label>}
      <div className="flex flex-row justify-between w-full relatite ">
        <input
          {...props}
          required={!!required}
          onBlur={handleBlurEvent}
          value={text}
          maxLength={maxLength}
          onChange={handleChange}
          className={`w-full border-b outline-none text-gray-600 font-medium ${!campoNecessario ? "border-secondary-tim" : "border-vermelho-erro"}`}
        />
        {icon && icon}
      </div>
      {required && campoNecessario && <span className="text-xs text-vermelho-erro mr-1">{required}</span>}
      {invalidText && valorInvalido && <span className="text-xs text-vermelho-erro">{invalidText}</span>}
    </div>
  );
}
