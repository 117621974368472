import { UserCircleIcon, UserIcon, PhoneIcon, CalendarDaysIcon } from "@heroicons/react/24/solid";
import React, { Fragment, useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { passoCarrinhoState } from "../../atoms/passoCarrinhoAtom";
import { planoSelecionadoState } from "../../atoms/planoSelecionadoAtom";
import { dadosClienteState } from "../../atoms/dadosClienteAtom";
import CustomInput from "../input/CustomInput";
import { formataDataCampoTexto, formataItemPrice, formataTelefone, validaDataInput } from "../../utils/textUtils";
import { processoState } from "../../atoms/processAtom";
import { pushEventToDataLayer } from "../../utils/gtm";
import { formataCPF, validaCPF } from "../../utils/validaCpf";
import { DDDPorEstado } from "../../lib/api/ddd";
import { Combobox, Listbox, Transition } from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/24/outline";

export default function DadosCliente() {
  const [passoCarrinho, setPassoCarrinho] = useRecoilState(passoCarrinhoState);
  const [planoSelecionado, setPlanoSelecionado] = useRecoilState(planoSelecionadoState);
  const [dadosCliente, setDadosCliente] = useRecoilState(dadosClienteState);
  const [processo, setProcesso] = useRecoilState(processoState);
  const [servicoAquisicao, setServicoAquisicao] = useState(
    processo.fluxo === "aquisicao" && !processo.identificacao ? (processo.telefoneCliente ? "portabilidade" : "nova-linha") : null
  );
  const [numeroTemporario, setNumeroTemporario] = useState(true);
  const [ddd, setDDD] = useState(null);
  const [erroDDD, setErroDDD] = useState(null);
  // const [camposNecessarios, setCamposNecessarios] = useState([
  //   "dataNascimentoCliente",
  //   "cpfCliente",
  // ]);
  const [dadosDigitados, setDadosDigitados] = useState({ ...dadosCliente } || {});
  console.log(processo);
  const handleChangeInput = (e) => {
    dadosDigitados[e.target.name] = e.target.value;
    setDadosDigitados({ ...dadosDigitados });
  };

  const handleContinuar = (e) => {
    e.preventDefault();
    if (!dadosDigitados?.nomeCliente) {
      return;
    }
    if (!validaCPF(dadosDigitados.cpfCliente)) {
      setErroDDD("Numero de CPF inválido");
      return;
    }

    if (!validaDataInput(dadosDigitados.dataNascimentoCliente)) {
      setErroDDD("Data de nascimento inválida");
      return;
    }

    if (processo.tipo_processo === "aquisicao" && !dadosDigitados?.telefoneContato) {
      setErroDDD("Necesário informar um telefone para contato");
      return;
    }

    if (dadosDigitados.telefoneContato) {
      dadosDigitados.telefoneContato = dadosDigitados.telefoneContato.replace(/([^0-9])/gi, "");
    }

    pushEventToDataLayer({
      event: "add_user_info",
      currency: "BRL",
      ecommerce: {
        currency: "BRL",
        value: formataItemPrice(planoSelecionado?.valorFinal),
        ecommerce: {
          items: [
            {
              item_name: planoSelecionado?.planoDestinoExibicao,
              item_id: planoSelecionado?.planoDestinoExibicao + (planoSelecionado?.fidelizado ? "_f" : ""),
              item_price: formataItemPrice(planoSelecionado?.valorFinal),
              item_brand: planoSelecionado?.tipo,
              item_category: planoSelecionado?.destaque,
              quantity: 1,
              item_variant: planoSelecionado?.beneficiosPlano,
              currency: "BRL",
            },
          ],
        },
      },
    });

    if (servicoAquisicao === "nova-linha" && !ddd?.ddd) {
      setErroDDD("Necessário selecionar o DDD");
      return;
    }

    const numTelefone = servicoAquisicao === "portabilidade" || servicoAquisicao === null ? processo.telefoneEntrada : "";

    setProcesso({ ...processo, numeroTemporario, telefoneCliente: numTelefone, ddd: ddd?.ddd || processo.ddd });

    setDadosCliente(dadosDigitados);
    setPassoCarrinho(passoCarrinho + 1);
  };
  const handleVerPlanos = () => {
    setPassoCarrinho(0);
    setPlanoSelecionado(null);
  };

  useEffect(() => {
    pushEventToDataLayer({
      event: "begin_checkout",
      ecommerce: {
        currency: "BRL",
        value: formataItemPrice(planoSelecionado?.valorFinal),
        ecommerce: {
          items: [
            {
              item_name: planoSelecionado?.planoDestinoExibicao,
              item_id: planoSelecionado?.planoDestinoExibicao + (planoSelecionado?.fidelizado ? "_f" : ""),
              item_price: formataItemPrice(planoSelecionado?.valorFinal),
              item_brand: planoSelecionado?.tipo,
              item_category: planoSelecionado?.destaque,
              quantity: 1,
              item_variant: planoSelecionado?.beneficiosPlano,
              currency: "BRL",
            },
          ],
        },
      },
    });
  }, []);

  return (
    <div className="w-full bg-white">
      <form className="flex flex-col px-4 py-5 pr-8 space-y-5 md:px-14 controle-tamanho-tela text-secondary-tim" onSubmit={handleContinuar}>
        <h3 className="text-xl font-extrabold">
          {processo.fluxo === "aquisicao" && !processo.servicoAquisicao ? "O que deseja fazer?" : "Preencha seus dados"}{" "}
        </h3>
        {processo.fluxo === "aquisicao" && !processo.servicoAquisicao && (
          <>
            <div className="flex flex-wrap gap-2">
              <div className="flex flex-row items-center">
                <input
                  required
                  name="servico-aquisicao"
                  value="portabilidade"
                  type="radio"
                  className="w-5 h-5 border-0 rounded checked:bg-secondary-tim"
                  onChange={(e) => setServicoAquisicao(e.target.value)}
                  checked={servicoAquisicao === "portabilidade"}
                />
                <span className="ml-2 text-xs font-semibold text-secondary-tim">
                  Trazer meu número <strong className=" text-sm">{formataTelefone(processo?.telefoneEntrada)}</strong> para a TIM
                </span>
              </div>
              <div className="flex flex-row items-center">
                <input
                  required
                  name="servico-aquisicao"
                  value="nova-linha"
                  type="radio"
                  className="w-5 h-5 border-0 rounded checked:bg-secondary-tim"
                  onChange={(e) => setServicoAquisicao(e.target.value)}
                  checked={servicoAquisicao === "nova-linha"}
                />
                <span className="ml-2 text-xs font-semibold text-secondary-tim">Nova Linha</span>
              </div>
            </div>
            {/* {servicoAquisicao === "portabilidade" && (
              <>
                <GerarNumeroTemporario callback={setNumeroTemporario} />
              </>
            )} */}
            {servicoAquisicao === "nova-linha" && (
              <div className="w-full sm:max-w-xs">
                <p className="text-sm font-semibold">Selecione o DDD da nova linha:</p>
                {erroDDD && <p className="text-xs font-semibold text-red-400">{erroDDD}</p>}
                <DDDNovaLinha callback={setDDD} />
              </div>
            )}
          </>
        )}
        <CustomInput
          value={dadosCliente?.nomeCliente}
          onChange={handleChangeInput}
          name="nomeCliente"
          lable="Digite seu nome completo*"
          type="text"
          required="Campo obrigatório."
          icon={<UserIcon className="icon-input" />}
        />
        {processo?.telefoneCliente && (
          <CustomInput
            value={formataTelefone(processo?.telefoneCliente)}
            name="telefoneCliente"
            lable="Telefone para Plano"
            type="text"
            disabled
            icon={<PhoneIcon className="icon-input" />}
          />
        )}
        {processo?.fluxo === "aquisicao" && (
          <CustomInput
            required="Campo obrigatório."
            value={formataTelefone(dadosCliente?.telefoneContato)}
            name="telefoneContato"
            onChange={handleChangeInput}
            lable="Telefone para Contato*"
            formatFx={formataTelefone}
            type="text"
            icon={<PhoneIcon className="icon-input" />}
          />
        )}
        <CustomInput
          value={dadosCliente?.dataNascimentoCliente}
          onChange={handleChangeInput}
          name="dataNascimentoCliente"
          lable="Digite sua data de nascimento*"
          type="text"
          required="Campo obrigatório."
          invalidText={"Por favor, digite uma data válida."}
          formatFx={formataDataCampoTexto}
          fxOnChange={true}
          validFx={validaDataInput}
          maxLength={10}
          icon={<CalendarDaysIcon className="icon-input" />}
        />

        <CustomInput
          value={dadosCliente?.nomeMaeCliente}
          onChange={handleChangeInput}
          name="nomeMaeCliente"
          lable="Digite o nome da sua mãe*"
          type="text"
          required="Campo obrigatório."
          icon={<UserIcon className="icon-input" />}
        />

        {/* {camposNecessarios.includes("rgCliente") && (
          <CustomInput
            value={dadosCliente?.rgCliente}
            onChange={handleChangeInput}
            name="rgCliente"
            lable="Digite seu RG*"
            type="text"
            invalidText={"Valor inválido"}
            required="Campo obrigatório."
            icon={<IdentificationIcon className="icon-input" />}
          />
        )} */}

        <CustomInput
          value={dadosCliente?.cpfCliente}
          onChange={handleChangeInput}
          name="cpfCliente"
          lable="Digite seu CPF*"
          type="text"
          validFx={validaCPF}
          invalidText={"CPF inválido"}
          formatFx={formataCPF}
          required="Campo obrigatório."
          icon={<UserCircleIcon className="icon-input" />}
        />

        <button type="submit" className="w-full py-3 text-xs font-extrabold text-white uppercase rounded-full bg-secondary-tim">
          Continuar
        </button>
        <button
          type="button"
          onClick={handleVerPlanos}
          className="w-full py-3 text-xs font-extrabold uppercase bg-white border rounded-full text-secondary-tim border-secondary-tim"
        >
          Ver outros planos
        </button>
      </form>
    </div>
  );
}

function DDDNovaLinha({ callback }) {
  const [estadoSelecionado, setEstadoSelecionado] = useState(null);
  const [dddSelecionado, setDddSelecionado] = useState(null);
  const [query, setQuery] = useState("");
  const estados = DDDPorEstado;

  const estadosFiltrados =
    query === "" ? estados : estados.filter((es) => es.nome.toLowerCase().replace(/\s+/g, "").includes(query.toLowerCase().replace(/\s+/g, "")));

  useEffect(() => {
    let preenchido = false;

    if (estadoSelecionado?.nome && dddSelecionado) {
      preenchido = true;
    }
    callback({ estado: estadoSelecionado?.nome, ddd: dddSelecionado }, "nova-linha", preenchido);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [estadoSelecionado, dddSelecionado]);

  useEffect(() => {
    if (estadoSelecionado?.ddds?.length === 1) {
      setDddSelecionado(estadoSelecionado?.ddds[0]);
    } else {
      setDddSelecionado(null);
    }
  }, [estadoSelecionado]);

  return (
    <>
      <div className="flex flex-col justify-between w-full relatite ">
        <label htmlFor="operadora-atual" className="block text-sm font-light leading-6  text-gray-900">
          Selecione o estado
        </label>
        <Combobox value={estadoSelecionado} onChange={setEstadoSelecionado} nullable>
          <div className="z-50 relative mt-1">
            <div className="relative w-full cursor-default overflow-hidden  bg-white text-left border-b border-secondary-tim outline-none text-gray-600 font-medium focus:outline-none focus-visible:ring-2 focus-visible:ring-white/75 focus-visible:ring-offset-2 focus-visible:ring-offset-teal-300 sm:text-sm">
              <Combobox.Input
                className="w-full border-none py-2 pl-1 pr-10 text-sm leading-5 text-gray-800 focus:ring-0"
                displayValue={(estado) => estado?.nome}
                onChange={(event) => setQuery(event.target.value)}
              />
              <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
                <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
              </Combobox.Button>
            </div>
            <Transition
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
              afterLeave={() => setQuery("")}
            >
              <Combobox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg  focus:outline-none sm:text-sm">
                {estadosFiltrados.length === 0 && query !== "" ? (
                  <div className="relative cursor-default select-none px-4 py-2 text-gray-700">Nada encontrado.</div>
                ) : (
                  estadosFiltrados.map((estado) => (
                    <Combobox.Option
                      key={estado.sigla}
                      className={({ active }) =>
                        `relative cursor-default select-none py-2 pl-10 pr-4 ${active ? "bg-secondary-tim text-white" : "text-secondary-tim"}`
                      }
                      value={estado}
                    >
                      {({ selected, active }) => (
                        <>
                          <span className={`block truncate ${selected ? "font-medium" : "font-normal"}`}>{estado.nome}</span>
                          {selected ? (
                            <span className={`absolute inset-y-0 left-0 flex items-center pl-3 ${active ? "text-white" : "text-secondary-tim"}`}>
                              <CheckIcon className="h-5 w-5" aria-hidden="true" />
                            </span>
                          ) : null}
                        </>
                      )}
                    </Combobox.Option>
                  ))
                )}
              </Combobox.Options>
            </Transition>
          </div>
        </Combobox>
        {estadoSelecionado?.sigla && (
          <div>
            {estadoSelecionado?.ddds?.length === 1 && (
              <>
                <label className="block text-sm font-light leading-6 mt-3 text-gray-900">DDD Selecionado</label>
                <span className="py-2 w-full mx-auto rounded-full border border-secondary-tim text-secondary-tim font-medium block text-center">
                  {estadoSelecionado.ddds[0]}
                </span>
              </>
            )}

            {estadoSelecionado?.ddds?.length > 1 && (
              <>
                <label className="block text-sm font-light leading-6 mt-3 text-gray-900">Selecione o DDD</label>
                <Listbox value={dddSelecionado} onChange={setDddSelecionado}>
                  <div className="relative mt-1 z-40">
                    <Listbox.Button className="relative w-full cursor-default bg-white py-4 pl-1 pr-10 text-left border-b border-secondary-tim outline-none text-gray-800 font-medium  focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white/75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm">
                      <span className="block truncate">{dddSelecionado}</span>
                      <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                        <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                      </span>
                    </Listbox.Button>
                    <Transition as={Fragment} leave="transition ease-in duration-100" leaveFrom="opacity-100" leaveTo="opacity-0">
                      <Listbox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm">
                        {estadoSelecionado?.ddds.map((ddd, dddIdx) => (
                          <Listbox.Option
                            key={dddIdx}
                            className={({ active }) =>
                              `relative cursor-default select-none py-2 pl-10 pr-4 ${active ? "bg-secondary-tim text-white" : "text-secondary-tim"}`
                            }
                            value={ddd}
                          >
                            {({ selected, active }) => (
                              <>
                                <span className={`block truncate ${selected ? "font-medium" : "font-normal"}`}>{ddd}</span>
                                {selected ? (
                                  <span
                                    className={`absolute inset-y-0 left-0 flex items-center pl-3 text-secondary-tim ${
                                      active ? "text-white" : "text-secondary-tim"
                                    } `}
                                  >
                                    <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                  </span>
                                ) : null}
                              </>
                            )}
                          </Listbox.Option>
                        ))}
                      </Listbox.Options>
                    </Transition>
                  </div>
                </Listbox>
              </>
            )}
          </div>
        )}
      </div>
    </>
  );
}
