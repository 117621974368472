import React from "react";
import { useRecoilState } from "recoil";
import {
  maxPassosSelector,
  passoCarrinhoState,
  ultimoPassoCarrinhoSelector,
} from "../../atoms/passoCarrinhoAtom";

export default function PassosCarrinho() {
  const [passoCarrinho] = useRecoilState(passoCarrinhoState);
  const [maxPassos] = useRecoilState(maxPassosSelector);
  const [ultimoPassoCarrinho] = useRecoilState(ultimoPassoCarrinhoSelector);
  return (
    <>
      {passoCarrinho > 0 && !ultimoPassoCarrinho && (
        <div className="flex flex-col mx-8 grow">
          <div className="flex flex-row w-full h-2 mx-2 rounded-2xl bg-cinza-base">
            <div
              className={` h-full bg-verde-tim-dark rounded-2xl w-${passoCarrinho}/${maxPassos}`}
            ></div>
          </div>
          <span className="inline-block mt-1 text-xs font-bold text-center text-white">
            Passo {passoCarrinho} de {maxPassos}
          </span>
        </div>
      )}
    </>
  );
}
