import React, { useEffect, useState } from "react";

export default function Loading({ show, speed, ...rest }) {
  const [position, setPosition] = useState(0);

  useEffect(() => {
    let p = 0;

    const interval = setInterval(() => {
      p = p + 10;
      if (p > 100) p = 0;

      setPosition(p);
    }, speed);

    return () => {
      clearInterval(interval);
    };
  }, [show]);

  return (
    <>
      {show && (
        <div {...rest}>
          <div className="relative pt-1">
            <div className="flex h-2 mb-4 overflow-hidden text-xs border rounded bg-lightBlue-200 border-secondary-tim-light">
              <div
                style={{
                  width: "100%",

                  transform: `scaleX(${position}%) translateX(0)`,
                  transition: "transform",
                  transitionTimingFunction: "revert-layer",
                  transitionDuration: "1000ms",
                }}
                className="flex flex-col justify-center text-center shadow-none whitespace-nowrap text-primary bg-secondary-tim-light"
              ></div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
