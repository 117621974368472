import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

import { useCookies } from "react-cookie";
import { Navigate, Route } from "react-router";
import { useRecoilState } from "recoil";
import { dadosClienteState } from "../atoms/dadosClienteAtom";
import { passoCarrinhoState, ultimoPassoCarrinhoSelector } from "../atoms/passoCarrinhoAtom";
import { planoSelecionadoState } from "../atoms/planoSelecionadoAtom";
import { processoState } from "../atoms/processAtom";

import DadosCliente from "../components/carrinho/DadosCliente";
import DadosContato from "../components/carrinho/DadosContato";
import DadosPagamento from "../components/carrinho/DadosPagamento";
import Finalizacao from "../components/carrinho/Finalizacao";
import PlanoSelecionado from "../components/carrinho/PlanoSelecionado";
import { pushEventToDataLayer } from "../utils/gtm";
import { hash256 } from "../utils/hashing";
import { formataItemPrice, validaTelefone } from "../utils/textUtils";
import { ofertaPorId } from "../lib/api/ofertas";
import { dadosOfertaeState } from "../atoms/dadosOfertaAtom";
import { convertApiAntiga } from "../lib/migracao/deParaCamposOferta";
import Loading from "../components/utils/Loading";

export default function Carrinho() {
  const [processo, setProcesso] = useRecoilState(processoState);
  const [dadosOferta, setDadosOferta] = useRecoilState(dadosOfertaeState);
  const [passoCarrinho, setPassoCarrinho] = useRecoilState(passoCarrinhoState);
  const [ultimoPassoCarrinho] = useRecoilState(ultimoPassoCarrinhoSelector);
  const [planoSelecionado, setPlanoSelecionado] = useRecoilState(planoSelecionadoState);
  const [dadosCliente] = useRecoilState(dadosClienteState);
  const [cookies] = useCookies(["telefoneCliente"]);

  const [loading, setLoading] = useState(null);

  const [urlParams] = useSearchParams();
  const oferta = urlParams.get("oferta");
  let ani = urlParams.get("ani");
  const ddd = ani?.substring(0, 2);
  const nomeCliente = urlParams.get("nome");
  const origem = urlParams.get("utm_source") || "portal";
  const campanha = urlParams.get("utm_campaign");
  const medium = urlParams.get("utm_medium");
  const fluxo = urlParams.get("utm_campaign") === "aquisicao" ? "aquisicao" : "rentabilizacao";
  const operadora = urlParams.get("operadora");

  const direcao = "outbound";

  const handleVoltarPasso = () => {
    if (passoCarrinho === 1) {
      setPlanoSelecionado(null);
    }
    setPassoCarrinho(passoCarrinho - 1);
  };
  const payloadEvent = (eventName) => {
    return {
      eventName,
      payload: {
        phone: hash256(processo?.telefoneCliente),
        // content_id: planoSelecionado?.id,
        value: formataItemPrice(planoSelecionado?.valorFinal),
        email: dadosCliente?.emailCliente ? hash256(dadosCliente?.emailCliente) : "",
      },
    };
  };

  const fetcherOferta = async (idOferta) => {
    setLoading(true);

    console.debug("fetcherOferta", idOferta);
    let data = await ofertaPorId(idOferta);
    setProcesso({
      ...processo,
      origem,
      fluxo,
      operadora,
      ddd,
      dadosOferta: true,
      nomeCliente,
      telefoneCliente: ani,
      telefoneEntrada: ani,
      tipo_processo: campanha,
    });
    setDadosOferta([data]);

    data = convertApiAntiga([data] || []);
    setPlanoSelecionado(data?.[0]);
    setLoading(false);
    setPassoCarrinho(1);
  };

  useEffect(() => {
    // if (cookies?.processo) {
    //   setProcesso(cookies?.processo);
    // }
    if (!dadosCliente?.nomeCliente) {
      setPassoCarrinho(1);
    }
  }, []);

  useEffect(() => {
    pushEventToDataLayer({
      event: "dimensoes_personalizadas",
      userId: cookies?.processId || dadosCliente?.pid,
      ep_email: dadosCliente?.emailCliente ? hash256(dadosCliente?.emailCliente) : "",
      ep_telefone: hash256(processo?.telefoneCliente),
    });
    document.body.scrollTop = document.documentElement.scrollTop = 0;
  }, [passoCarrinho]);

  useEffect(() => {
    console.log("ani----", validaTelefone(ani));
    if (oferta && validaTelefone(ani)) {
      fetcherOferta(oferta);
    } else {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [oferta]);
  console.trace("passo", passoCarrinho);

  if (!planoSelecionado && loading === true) {
    return (
      <div className="w-full h-24 bg-white p-6">
        <Loading text="" show={loading} speed={120} className="w-8/12 mx-auto " />
      </div>
    );
  }
  if (!planoSelecionado && loading != null) {
    return <Navigate to={`/?${urlParams.toString()}`} />;
  }
  return (
    <>
      {/* {!planoSelecionado && <Navigate replace to="/ofertas" />} */}
      <div className="flex flex-col bg-white">
        <PlanoSelecionado
          planoSelecionado={planoSelecionado}
          processo={processo}
          handleVoltarPasso={handleVoltarPasso}
          tagEventPayload={payloadEvent}
        />
        {passoCarrinho === 1 && <DadosCliente tagEventPayload={payloadEvent} />}
        {passoCarrinho === 2 && <DadosContato tagEventPayload={payloadEvent} />}
        {passoCarrinho === 3 && <DadosPagamento tagEventPayload={payloadEvent} />}
        {ultimoPassoCarrinho && <Finalizacao tagEventPayload={payloadEvent} />}
      </div>
    </>
  );
}
