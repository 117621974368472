import React, { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { dadosClienteState } from "../../atoms/dadosClienteAtom";
import CustomInput from "../input/CustomInput";
import Endereco from "./Endereco";
import { formataItemPrice, validEmail } from "../../utils/textUtils";
import { EnvelopeIcon } from "@heroicons/react/24/solid";
import { passoCarrinhoState } from "../../atoms/passoCarrinhoAtom";
import { contratarPlano } from "./DadosPagamento";
import { ArrowPathIcon } from "@heroicons/react/24/outline";
import { planoSelecionadoState } from "../../atoms/planoSelecionadoAtom";
import { useCookies } from "react-cookie";
import { processoState } from "../../atoms/processAtom";
import { pushEventToDataLayer } from "../../utils/gtm";

export default function DadosContato({ tagEventPayload }) {
  const [dadosCliente, setDadosCliente] = useRecoilState(dadosClienteState);
  const [passoCarrinho, setPassoCarrinho] = useRecoilState(passoCarrinhoState);
  const [planoSelecionado] = useRecoilState(planoSelecionadoState);
  const [showEndereco, setShowEndereco] = useState(false);
  const [dadosDigitados, setDadosDigitados] = useState({});
  const [endereco, setEnderco] = useState("");
  const [erro, setErro] = useState(null);
  const [cookie] = useCookies(["processId"]);
  const [processo] = useRecoilState(processoState);
  const [camposNecessarios, setCamposNecessarios] = useState([]);

  const handleChangeInput = (e) => {
    dadosDigitados[e.target.name] = e.target.value;
    setDadosDigitados({ ...dadosDigitados });
  };
  const handleContinuar = (e) => {
    pushEventToDataLayer({
      event: "add_shipping_info",
      ecommerce: {
        currency: "BRL",
        value: formataItemPrice(planoSelecionado?.valorFinal),
        ecommerce: {
          items: [
            {
              item_name: planoSelecionado?.planoDestinoExibicao,
              item_id: planoSelecionado?.planoDestinoExibicao + (planoSelecionado?.fidelizado ? "_f" : ""),
              item_price: formataItemPrice(planoSelecionado?.valorFinal),
              item_brand: planoSelecionado?.tipo,
              item_category: planoSelecionado?.destaque,
              quantity: 1,
              item_variant: planoSelecionado?.beneficiosPlano,
              currency: "BRL",
            },
          ],
        },
      },
    });
    e.preventDefault();
    setErro(null);

    if (
      !dadosDigitados?.emailCliente ||
      // eslint-disable-next-line eqeqeq
      dadosDigitados?.emailClienteConfirmacao != dadosDigitados?.emailCliente
    ) {
      setErro({ msg: "Digite os dois e-mail iguais." });
      return;
    }

    if (processo?.tipo_processo === "pre_controle" || processo?.tipo_processo === "aquisicao") {
      if (!endereco) {
        setErro({ msg: "Necessário informar o endereço completo." });
        return;
      }
    }

    if (!planoSelecionado?.valorFinal) {
      setErro({
        msg: "Ocorreu um erro, por favor, tente novamente mais tarde.",
      });
      return;
    }
    const d = {
      ...dadosCliente,
      emailCliente: dadosDigitados.emailCliente,
      pid: cookie?.processId,
    };
    setDadosCliente(d);
    setPassoCarrinho(passoCarrinho + 1);
  };

  useEffect(() => {
    if (processo?.tipo_processo === "pre_controle" || processo?.tipo_processo === "aquisicao" || planoSelecionado?.express) {
      setCamposNecessarios(["endereco"]);
    }
  }, []);

  useEffect(() => {
    const { endereco: end } = dadosCliente;
    if (!end) return;

    let e = `${end.nomeRua}, ${end.numero}`;

    if (end.complemento) {
      e += `, ${end.complemento}`;
    }
    if (end.bairro) {
      e += `, ${end.bairro}`;
    }
    e += ` - ${end.cidade}/${end.estado}`;
    e += ` - ${end.cep}`;
    setEnderco(e);
  }, [dadosCliente]);

  return (
    <div className="w-full bg-white">
      <form onSubmit={handleContinuar} className="flex flex-col px-4 py-5 pr-8 space-y-5 controle-tamanho-tela text-secondary-tim md:px-14">
        <h3 className="text-xl font-extrabold">Continue preenchendo:</h3>
        <div className="flex flex-col mt-5 space-y-3">
          <CustomInput
            onChange={handleChangeInput}
            name="emailCliente"
            id="email1"
            lable="Digite seu e-mail*"
            type="email"
            required="Campo obrigatório."
            validFx={validEmail}
            invalidText={"E-mail inválido"}
            icon={<EnvelopeIcon className="icon-input" />}
          />
          <CustomInput
            onChange={handleChangeInput}
            name="emailClienteConfirmacao"
            id="email2"
            lable="Confirme seu e-mail*"
            type="email"
            required="Confirme o e-mail"
            validFx={validEmail}
            invalidText={"E-mail inválido"}
            icon={<EnvelopeIcon className="icon-input" />}
          />
          {camposNecessarios.includes("endereco") && (
            <CustomInput
              lable="Endereço completo*"
              type="email"
              name="endereco"
              readOnly
              value={endereco}
              onClick={() => setShowEndereco(true)}
              icon={null}
            />
          )}
          {erro && <span className="text-xs text-vermelho-erro">{erro.msg}</span>}{" "}
          <button type="submit" className="w-full py-4 text-sm font-extrabold text-white uppercase rounded-full bg-secondary-tim">
            Continuar e Contratar
          </button>
        </div>
      </form>
      <Endereco exibir={showEndereco} onClose={() => setShowEndereco(false)} />
    </div>
  );
}
