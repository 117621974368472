import React from "react";
import Img from "./Img";
export default function ImageApps({ oferta }) {
  let apps = [];
  let appsPromo = [];

  if (typeof oferta?.apps === "string") {
    apps = oferta?.apps?.split(",");
  } else {
    apps = oferta?.apps;
  }

  if (typeof oferta?.appsPromo === "string") {
    appsPromo = oferta?.appsPromo?.split(",");
  } else {
    appsPromo = oferta?.appsPromo;
  }
  return (
    <>
      {!oferta ? (
        <></>
      ) : (
        <div className="flex flex-row space-x-2 text-secondary-tim">
          {apps?.[0] && (
            <>
              <div className="flex flex-wrap py-2 ">
                {apps.map((app) => {
                  return <Img key={app} app={app} />;
                })}
              </div>
            </>
          )}
          {appsPromo?.[0] && (
            <>
              <span className="py-2 text-base font-bold">+</span>
              <div className="flex flex-col items-center px-3 py-2 space-x-1 border grow border-secondary-tim rounded-xl border-secontext-secondary-tim">
                <div className="grid grid-cols-3">
                  {appsPromo?.map((app) => {
                    return <Img key={app} app={app} />;
                  })}
                </div>
                <span className="text-xs font-medium">{oferta.appsPromoInfo}</span>
              </div>
            </>
          )}
        </div>
      )}
    </>
  );
}
