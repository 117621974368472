import { formataEndereco } from "../utils/textUtils";
import BonusInternet from "./utils/BonusInternet";

export default function ResumoPedido({ planoSelecionado, dadosCliente }) {
  return (
    <>
      <div className="w-full">
        <div className="flex flex-col justify-around text-secondary-tim">
          <h3 className="px-2 inline py-2 font-light text-secondary-tim ">Resumo do Pedido</h3>
          <div className="px-4 py-2">
            <h4 className="text-lg font-medium text-secondary-tim leading-tight">{planoSelecionado.planoDestinoExibicao}</h4>
            <p className="text-sm font-light ">
              <em>{planoSelecionado.fidelizado ? "Com fidelização de 12 meses" : "Plano sem fidelização"}</em>
            </p>
            <h5 className="mt-2 text-base font-light text-gray-700">Internet</h5>
            <div className="-mt-1">
              <BonusInternet lista={true} oferta={planoSelecionado} coresEqualizadas={true} />
            </div>

            {dadosCliente?.formaPagamento === "fatura" && (
              <>
                <h5 className="mt-1 text-base font-light text-gray-700">Dados da Fatura</h5>
                <p className="font-bold">
                  {" "}
                  <span className="font-light ">Tipo </span>
                  {dadosCliente?.tipoConta}
                </p>
                <p className="font-bold">
                  <span className="font-light">Vencimento todo dia </span>
                  {dadosCliente?.diaVencimento}
                </p>
                <p className="font-bold">
                  <span className="font-light">E-mail </span>
                  {dadosCliente?.emailCliente}
                </p>
                <p className="font-bold">
                  <span className="font-light">Endereço </span>
                  {formataEndereco(dadosCliente?.endereco, false)}
                </p>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
