import React from "react";

export default function Oferta({ exibeSaudacao, nomeCliente, exibePlano }) {
  return (
    <div className="w-full bg-primary-tim">
      <div className="px-8 py-6 space-y-3 text-white md:text-center controle-tamanho-tela md:px-0">
        <div className="text-xl ">
          {exibeSaudacao && nomeCliente && (
            <p className="mb-3 font-light">
              Olá, <span className="font-normal">{nomeCliente.split(" ")[0]}</span>
            </p>
          )}
          <p className="text-sm">Surpreenda-se com a rede TIM</p>
          <p>A maior rede móvel do Brasil é líder no 5G</p>
        </div>
        <div>
          {/* <p className="text-sm">
            Uma parceria da TIM com um banco digital completo.
          </p> */}
          <div className="flex flex-row mt-2 space-x-2 md:mt-4 md:justify-center">
            <img src="assets/c6bank.png" alt="Logo C6" className="h-5 mr-2 text-white invert md:h-7" />
            <img src="assets/tim-logo.png" alt="Logo TIM" className="h-5 text-white md:h-7" />
          </div>
        </div>
      </div>
    </div>
  );
}
