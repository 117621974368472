import { atom } from "recoil";

export const processoState = atom({
  key: "processoState",
  default: null,
  effects: [
    ({ onSet }) => {
      onSet((newData) => {
        console.debug("processoState", newData);
      });
    },
  ],
});
