import { Switch } from "@headlessui/react";

const Switcher = ({ titulo, valor, callback, comprimido }) => {
  return (
    <Switch.Group>
      <div className="flex items-center my-2">
        <Switch
          checked={valor}
          onChange={callback}
          className={`${
            valor ? "bg-emerald-200" : "bg-gray-300 border border-gray-50"
          } relative inline-flex h-4 w-11 items-center rounded-full transition-colors focus:outline-none ring-0`}
        >
          <span
            className={`${
              valor ? "translate-x-6 bg-verde-tim-dark" : "translate-x-0 bg-white"
            } inline-block h-5 w-5 transform rounded-full transition-transform drop-shadow-lg shadow-lg`}
          />
        </Switch>
        <Switch.Label className={`${!comprimido ? "ml-4" : "ml-2"} text-gray-700 text-sm`}>{titulo}</Switch.Label>
      </div>
    </Switch.Group>
  );
};

export default Switcher;
