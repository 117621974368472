import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { obterData } from "../../../utils/validaData";

const diasVencimento = [
  { dia: "1" },
  // { dia: "7" },
  { dia: "10" },
  { dia: "12" },
  { dia: "15" },
  { dia: "20" },
  // { dia: "25" },
];

//TODO
function proximoPagamento(dia) {
  return dia.toLocaleDateString("pt-BR", {
    year: "numeric",
    month: "numeric",
    day: "numeric",
  });
}

function Fatura({ callbackSelecao, tipoFatura, diaVencimento, tipoConta }) {
  const [diasDisponiveis, setDiasDisponiveis] = useState([]);
  const [diaSelecionado, setDiaSelecionado] = useState(null);
  const [melhorDia, setMelhorDia] = useState(null);

  useEffect(() => {
    setDiasDisponiveis(diasVencimento);
    if (diaVencimento && tipoConta === tipoFatura.tipoConta) {
      setDiaSelecionado({ dia: diaVencimento });
    }
  }, []);

  const hanldeSalvar = () => {
    callbackSelecao &&
      callbackSelecao(diaSelecionado.dia, melhorDia?.getDate());
  };
  const handleSelecaoDia = (d) => {
    if (diaSelecionado?.dia === d.dia) {
      setDiaSelecionado(null);
      return;
    }
    setDiaSelecionado(d);
  };
  useEffect(() => {
    const melhorVencimento = obterData(new Date());
    setMelhorDia(melhorVencimento || null);
  }, []);

  return (
    <div className="">
      <h4 className="mb-1 text-sm font-bold text-secondary-tim">
        Escolha o vencimento
      </h4>
      <div>
        <div className="flex flex-row ">
          {diasDisponiveis.map((d, i) => (
            <span
              className={
                // eslint-disable-next-line eqeqeq
                melhorDia?.getDate() == d.dia && !diaSelecionado?.dia
                  ? " shadow-lg bg-azul-tim-light bg-opacity-50 rounded-lg px-2 py-4"
                  : "px-2 py-4"
              }
            >
              <span
                key={"selecao_dia_" + d + "___" + i}
                onClick={() => handleSelecaoDia(d)}
                className={`px-3 py-2 cursor-pointer font-bold text-sm rounded-lg ${
                  diaSelecionado?.dia === d.dia
                    ? "bg-azul-tim-light text-secondary-tim"
                    : "bg-secondary-tim text-white"
                }`}
              >
                {d.dia}
              </span>
            </span>
          ))}
        </div>
        {melhorDia && !diaSelecionado?.dia && (
          <span className="inline-block p-2 m-4 ml-0 text-sm font-medium rounded-md shadow-lg bg-azul-tim-light text-secondary-tim">
            Selecionando o dia destacado, você só paga em
            <em className="p-1">{proximoPagamento(melhorDia)}</em>
          </span>
        )}
      </div>
      <button
        onClick={hanldeSalvar}
        className="mt-2 text-white btn bg-secondary-tim"
      >
        Salvar
      </button>
    </div>
  );
}

export default Fatura;
