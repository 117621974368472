const server = process.env.REACT_APP_ENDPOINT_API;
const org = process.env.REACT_APP_ID_ORGANIZACAO;
const qtdPadrao = process.env["REACT_APP_QTD_OFERTAS"];

export async function indicacaoPlano({ url, args }) {
  const { ani, origem, fluxo, direcao, operadora, ddd, filtroPrioritario, express } = args;

  const qtdOfertas = process.env["REACT_APP_QTD_OFERTAS_" + origem.toUpperCase()] || qtdPadrao;
  const body = {
    ani,
    idOrganizacao: org,
    origem,
    direcao,
    fluxo,
    qtdOfertas,
    retornaOfertaPadrao: true,
    operadora,
    ddd,
    filtroPrioritario,
    express,
  };
  const response = await fetch(server + url, {
    method: "post",
    body: JSON.stringify(body),
    headers: {
      "content-type": "application/json",
      "x-api-key": process.env.REACT_APP_API_KEY_PORTAL,
    },
  }).catch(() => {
    return null;
  });

  if (response?.body) {
    return await response.json();
  }

  return null;
}

export async function processoIniciado(url) {
  const response = await fetch(server + url, {
    headers: {
      "content-type": "application/json",
      "x-api-key": process.env.REACT_APP_API_KEY_PORTAL,
    },
  }).catch(() => {
    return null;
  });

  if (response?.body) {
    return await response.json();
  }

  return null;
}

export async function confirmarPedido(payload) {
  const response = await fetch(server + "/oferta/pedido/portal/confirmar", {
    method: "post",
    body: JSON.stringify(payload),
    headers: {
      "content-type": "application/json",
      "x-api-key": process.env.REACT_APP_API_KEY_PORTAL,
    },
  }).catch(() => {
    return null;
  });

  if (response?.body) {
    return await response.json();
  }

  return null;
}

export async function pedidoDigital(payload) {
  const response = await fetch(server + "/oferta/pedido", {
    method: "post",
    body: JSON.stringify(payload),
    headers: {
      "content-type": "application/json",
      "x-api-key": process.env.REACT_APP_API_KEY_PORTAL,
    },
  }).catch(() => {
    return null;
  });

  if (response?.body) {
    return await response.json();
  }

  return null;
}

export async function ofertaPorId(idOferta) {
  const response = await fetch(server + "/oferta/" + idOferta, {
    method: "get",
    headers: {
      "content-type": "application/json",
      "x-api-key": process.env.REACT_APP_API_KEY_PORTAL,
    },
  }).catch(() => {
    return null;
  });

  if (response?.body) {
    return await response.json();
  }

  return null;
}

export async function confirmaPagamentoPedido(payload) {
  const response = await fetch(server + "/pedido/confirma-pagamento", {
    method: "post",
    body: JSON.stringify(payload),
    headers: {
      "content-type": "application/json",
      "x-api-key": process.env.REACT_APP_API_KEY_PORTAL,
    },
  }).catch(() => {
    return null;
  });

  if (response?.body) {
    return await response.json();
  }

  return null;
}
