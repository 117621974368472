import { XMarkIcon } from "@heroicons/react/24/solid";
import React, { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { dadosClienteState } from "../../../atoms/dadosClienteAtom";
import Fatura from "./Fatura";
import { criaSessao } from "../../../lib/api/digipay";
import { trataRetornos } from "../../../lib/utils/tratamentoDigipay";
import { ArrowPathIcon } from "@heroicons/react/24/outline";
import { processoState } from "../../../atoms/processAtom";
import { planoSelecionadoState } from "../../../atoms/planoSelecionadoAtom";

export default function FormaPagamento({ exibir, onClose, formaPagamento, tokenDigipay, loadingToken, callbackCartao }) {
  const [dadosCliente, setDadosCliente] = useRecoilState(dadosClienteState);
  const [processo] = useRecoilState(processoState);
  const [planoSelecionado] = useRecoilState(planoSelecionadoState);

  const [show, setShow] = useState(false);
  const [efeito, setEfeito] = useState(false);
  const [erro, setErro] = useState(null);
  const [transacaoAceita, setTransacaoAceita] = useState(null);
  const [loading, setLoading] = useState(null);
  const [sessaoDigipay, setSessaoDigipay] = useState(null);
  const [fetchData, setFetchData] = useState(true);

  const handleClose = () => {
    if (show) {
      setTimeout(() => {
        setShow(!show);
        onClose && onClose();
        if (transacaoAceita) {
          callbackCartao && callbackCartao();
        }
      }, 1000);
    }

    setEfeito(false);
  };

  useEffect(() => {
    setShow(!!exibir);

    if (exibir) {
      setTimeout(() => setEfeito(true), 100);
    }
  }, [exibir]);

  useEffect(() => {
    if (formaPagamento?.tipo === "cartao" && fetchData) {
      setErro(null);
      setLoading(true);
      const fetcher = async () => {
        setFetchData(false);

        const data = await criaSessao({
          token: tokenDigipay?.token,
          cpf: dadosCliente.cpfCliente,
          ani: processo.telefoneCliente,
          nome: dadosCliente.nomeCliente,
          email: dadosCliente.emailCliente,
          plano: planoSelecionado.codigo,
          subOfferId: planoSelecionado.subOfferId,
          fluxo: "adesao",
        });
        console.log("Consulta session");
        if (data?.sessionToken) {
          setSessaoDigipay(data?.sessionToken);
        }
        setLoading(false);
      };
      fetcher();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formaPagamento]);

  useEffect(() => {
    console.debug("loadingSessaoDigipay", loading);

    if (formaPagamento?.tipo === "cartao" && !sessaoDigipay && loading === false) {
      setErro({ msg: "Ocorreu um erro. Por favor, selecione um plano e tente novamente." });

      return;
    }

    if (formaPagamento?.tipo === "cartao" && sessaoDigipay) {
      // eslint-disable-next-line no-undef
      const service = initPaymentService("", document.getElementById("iframe-digipay"));
      trataRetornos(service, sessaoDigipay, callbackRetornosDigipay);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sessaoDigipay, loading]);

  // console.debug("tokenDigipay", tokenDigipay);
  console.debug("sessaoDigipay", sessaoDigipay);

  const handleContinuar = (e) => {
    e.preventDefault();

    handleClose();
  };
  const callbackRetornosDigipay = (data, sucesso) => {
    if (!sucesso) {
      setErro({ msg: data });
      return;
    }

    if (process.env.REACT_APP_LOCAL === "1") {
      data = { adesaoId: "1234567890" };
    }
    if (!data?.adesaoId) {
      setErro({ msg: "Ocorreu um erro na comunicação com sistema de cartão." });
      return;
    }

    setTransacaoAceita(data);

    setErro(null);
  };
  const handleSelecionaDiaPagamento = (dia, diaSugerido) => {
    const { tipoConta, tipo } = formaPagamento;
    setDadosCliente({
      ...dadosCliente,
      diaVencimento: dia,
      diaVencimentoSugerido: diaSugerido,
      tipoConta,
      formaPagamento: tipo,
      adesaoId: transacaoAceita?.adesaoId,
    });
    handleClose();
  };
  return (
    <>
      {show && (
        // Fundo escuro
        <div className="fixed z-50 items-end w-full h-screen bg-black bg-opacity-50 top-11">
          {/* Popup */}
          <div
            className={`z-50 fixed bottom-0 w-full p-6 transition-all duration-1000 ease-in-out bg-white rounded-t-2xl ${
              efeito ? "translate-y-0 visible" : "translate-y-full invisible"
            } ${formaPagamento?.tipo === "cartao" && sessaoDigipay && !transacaoAceita?.adesaoId && !erro?.msg ? "h-full" : ""}`}
          >
            <div className="flex flex-col h-full controle-tamanho-tela md:px-14">
              <div className="flex flex-row justify-end w-full">
                <XMarkIcon className="w-6 cursor-pointer text-secondary-tim" onClick={handleClose} />
              </div>
              <h3 className="text-xl font-extrabold text-secondary-tim">{formaPagamento.texto || "Fatura"}</h3>
              <form onSubmit={handleContinuar} className="flex flex-col h-full space-y-3">
                {(loading || loadingToken) && (
                  <div className="flex items-center w-full">
                    <ArrowPathIcon className="w-10 h-10 mx-auto mt-4 animate-spin" />
                  </div>
                )}
                {erro?.msg && <span className="py-10 text-lg font-light text-red-400">{erro.msg}</span>}
                {formaPagamento.tipo !== "cartao" && (
                  <Fatura
                    callbackSelecao={handleSelecionaDiaPagamento}
                    tipoFatura={formaPagamento}
                    diaVencimento={dadosCliente?.diaVencimento}
                    tipoConta={dadosCliente?.tipoConta}
                  />
                )}
                {!transacaoAceita?.adesaoId && (
                  <div id="iframe-digipay" className={`overflow-y-auto ${loading === false ? "h-full" : ""} ${erro?.msg ? "hidden" : ""}`}></div>
                )}
                {transacaoAceita?.adesaoId && (
                  <div className="flex flex-col justify-between mt-8">
                    <p className="text-3xl font-light text-center text-primary-tim">Transação autorizada.</p>
                    <button onClick={handleSelecionaDiaPagamento} className="mt-2 text-white btn bg-secondary-tim">
                      Continuar
                    </button>
                  </div>
                )}
              </form>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
