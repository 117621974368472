import React, { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";

import { criaSessao, getToken } from "../../../lib/api/digipay";
import { trataRetornos } from "../../../lib/utils/tratamentoDigipay";
import { confirmaPagamentoPedido, ofertaPorId } from "../../../lib/api/ofertas";
import Loading from "../../utils/Loading";
import { pushEventToDataLayer } from "../../../utils/gtm";
import { formataItemPrice, formataTelefone } from "../../../utils/textUtils";
import { ArrowLongUpIcon, Bars3Icon, CheckCircleIcon, ExclamationTriangleIcon, InformationCircleIcon, PhoneIcon } from "@heroicons/react/24/outline";
import Beneficios from "../../Beneficios";
import { getCentavosValor, getDezenasValor, getMedidaDados, getNumDados } from "../../../utils/separacaoTexto";
import { convertApiAntiga } from "../../../lib/migracao/deParaCamposOferta";
import CustomInput from "../../input/CustomInput";
import { formataCPF, validaCPF } from "../../../utils/validaCpf";
import { UserCircleIcon } from "@heroicons/react/24/solid";

function eventoNavegacao(event, planoSelecionado, payment_type) {
  let payload = {
    event,
    ecommerce: {
      currency: "BRL",
      value: formataItemPrice(planoSelecionado?.valorFinal),
      ecommerce: {
        items: [
          {
            item_name: planoSelecionado?.planoDestinoExibicao,
            item_id: planoSelecionado?.planoDestinoExibicao + (planoSelecionado?.fidelizado ? "_f" : ""),
            item_price: formataItemPrice(planoSelecionado?.valorFinal),
            item_brand: planoSelecionado?.tipo,
            item_category: planoSelecionado?.destaque,
            quantity: 1,
            item_variant: planoSelecionado?.beneficiosPlano,
            currency: "BRL",
          },
        ],
      },
    },
  };

  if (payment_type) {
    payload["payment_type"] = payment_type;
  }

  pushEventToDataLayer(payload);
}

export default function PagamentoBemobi({ ani, dadosCliente, pid, idOferta, origem }) {
  const [loading, setLoading] = useState(null);
  const [sessaoDigipay, setSessaoDigipay] = useState(null);
  const [erro, setErro] = useState(null);
  const [planoSelecionado, setPlanoSelecionado] = useState(null);
  const [transacaoAceita, setTransacaoAceita] = useState(null);
  const [showBeneficios, setShowBeneficios] = useState(false);

  const [dadosDigitados, setDadosDigitados] = useState({ ...dadosCliente } || {});
  const [testeErro, setTesteErro] = useState(false);
  const [tentativas, setTentativas] = useState(1);
  const callbackRetornosDigipay = async (data, sucesso) => {
    eventoNavegacao("begin_checkout", planoSelecionado, "Cartão de Crédito");

    if (!sucesso) {
      setErro({ message: "Houve um problema ao processar seu pedido. Tente novamente mais tarde." });
      return;
    }

    if (process.env.REACT_APP_LOCAL === "1") {
      data = { adesaoId: "1234567890" };
    }
    if (!data?.adesaoId) {
      setErro({ message: "Ocorreu um erro na comunicação com sistema de cartão." });
      return;
    }

    await confirmaPagamentoPedido({
      ani,
      pid,
      nomeCliente: dadosCliente.nomeCliente,
      confirmacao: data.adesaoId,
      idOferta,
      bonusPromo: planoSelecionado.bonus?.find((b) => b.nomeReferencia.includes("INTERNET 12M"))?.valor,
      bonusEspecial: planoSelecionado.bonus?.find((b) => b.nomeReferencia.includes("ESPECIAL 12M"))?.valor,
      origem,
      nomePlano: planoSelecionado.planoDestino.nome,
    });
    setTransacaoAceita(data);

    setErro(null);
  };

  const handleChangeInput = (e) => {
    dadosDigitados[e.target.name] = e.target.value;
    setDadosDigitados({ ...dadosDigitados });
  };
  const handleTentarNovamente = () => {
    setSessaoDigipay(null);
    setErro(null);
    setTesteErro(false);
    setTentativas(tentativas + 1);
  };
  const handleVoltar = (e) => {
    e.preventDefault();
    document.querySelector("#ancora").scrollIntoView({
      behavior: "smooth",
    });
  };
  const handleContinuar = async (e) => {
    e.preventDefault();
    try {
      if (!validaCPF(dadosDigitados.cpfCliente)) {
        return false;
      }
      setLoading(true);
      const tokenDigipay = await getToken()
        .then((data) => data)
        .catch((e) => {
          console.error("Erro token", e);
          return null;
        });

      if (!tokenDigipay) {
        setErro({ message: "Erro ao carregar as informaçõe. Por favor tente novamente." });
        setLoading(false);
        return;
      }

      const data = await criaSessao({
        token: tokenDigipay?.token,
        cpf: dadosDigitados.cpfCliente,
        ani: ani,
        nome: dadosDigitados.nomeCliente,
        email: dadosDigitados.emailCliente,
        plano: planoSelecionado.planoDestino.codigo,
        subOfferId: planoSelecionado.subOfferId,
        fluxo: "adesao",
      });

      if (data?.sessionToken) {
        setSessaoDigipay(data?.sessionToken);
        setTimeout(() => {
          setLoading(false);
          document.querySelector("#iframe-digipay").scrollIntoView({
            behavior: "smooth",
          });
        }, 2000);

        return;
      }

      setLoading(false);
      setErro({ message: "Erro ao carregar as informaçõe. Por favor tente novamente." });
    } catch (error) {}
  };
  useEffect(() => {
    const fetcherSessao = async () => {
      try {
        let responseOferta = await ofertaPorId(idOferta);
        if (!responseOferta) {
          setErro({ message: "Oferta não encontrada." });
          setLoading(false);
          return;
        }

        eventoNavegacao("begin_checkout", responseOferta);
        setPlanoSelecionado(responseOferta);
        setLoading(false);
      } catch (e) {
        console.error(e);
        setErro({ message: "Erro ao carregar as informaçõe. Por favor tente novamente." });
      }
    };

    setLoading(true);
    fetcherSessao();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ani, tentativas]);

  useEffect(() => {
    if (!sessaoDigipay && loading === false) {
      setErro({ message: "Ocorreu um erro. Por favor, selecione um plano e tente novamente." });
      return;
    }
    if (sessaoDigipay) {
      // eslint-disable-next-line no-undef
      const service = initPaymentService("", document.getElementById("iframe-digipay"));
      trataRetornos(service, sessaoDigipay, callbackRetornosDigipay);
    }
  }, [sessaoDigipay]);

  if (transacaoAceita) {
    return (
      <div className="flex items-center justify-center py-4 flex-col px-4">
        <CheckCircleIcon className="w-16 h-16 text-green-500" />
        <p className="font-medium text-xl text-secondary-tim uppercase">Pagamento aprovado</p>
        <div className="flex flex-row items-center my-4 space-x-2 px-2 max-w-sm border border-gray-600">
          <InformationCircleIcon className=" w-16 h-16 text-secondary-tim" />
          <div className="text-secondary-tim text-xs p-2">
            <p>Parabéns! seu novo plano e seus benefícios serão ativados automaticamente nas próximas horas.</p>
            <p>Você receberá notificações com as atualizações.</p>
            <p>Agora é só aguardar e desfrutar dos novos benefícios do seu TIM!</p>
          </div>
        </div>
      </div>
    );
  }

  if (erro?.message || testeErro) {
    return (
      <div className="flex py-8 h-screen flex-col items-center px-4">
        <ExclamationTriangleIcon className="w-14 h-14 text-red-400" />
        <p className="font-medium text-xl text-secondary-tim uppercase">Problemas com o pagamento</p>
        <div className="flex flex-row items-center my-4 space-x-2 px-2 max-w-sm border border-gray-600">
          <InformationCircleIcon className=" w-16 h-16 mx-2 text-yellow-500" />
          <div className="text-secondary-tim text-sm p-2">
            <p>
              Houve um problema ao processar o pagamento. Por favor, revise as informações do seu cartão e tente novamente.
              <p>Verifique os seguintes itens:</p>
            </p>
            <p>- CPF do títular do cartão</p>
            <p>- Dados do cartão</p>
            <p>- Limite disponível no cartão</p>
          </div>
        </div>
        <button
          type="button"
          onClick={handleTentarNovamente}
          className="w-full py-3 text-xs font-extrabold uppercase bg-white border rounded-full text-secondary-tim border-secondary-tim"
        >
          Tentar Novamente
        </button>
      </div>
    );
  }

  return (
    <>
      {loading && (
        <div className="fixed z-50 top-11  left-0 w-full h-screen bg-white bg-opacity-90 flex flex-col mx-auto items-center justify-center">
          <p className=" text-xl font-normalD px-8 text-secondary-tim">Carregando</p>
          <Loading text="Carregando oferta" show={loading} speed={120} className="w-8/12 mx-auto " />
        </div>
      )}
      <div id="ancora" className="flex flex-col slate-500 bg-opacity-10 w-full md:px-14">
        <div className="flex flex-col w-full p-4 md:py-8 md:mx-auto controle-tamanho-tela overflow-hidden  text-left leading-6 text-gray-800">
          <p className="text-sm font-light flex flex-row justify-between">
            Você está contratando
            <Bars3Icon className="w-6 inline" onClick={() => setShowBeneficios(true)} />
          </p>
          <p className="text-xl sm:text-2xl">
            <strong className=" font-semibold">{planoSelecionado?.planoDestino.exibicao}</strong>
          </p>
          <p className="flex flex-row  space-x-4 py-2 sm:space-x-8">
            <strong className=" font-normal mx-1 text-lg">
              <span className={` font-extrabold text-5xl sm:text-7xl`}>
                {getNumDados(planoSelecionado?.dadosTotal)}
                <span className={` text-3xl sm:text-4xl`}>{getMedidaDados(planoSelecionado?.dadosTotal)}</span>
              </span>
            </strong>
            <p className="font-semibold text-lg">
              <span className="flex flex-row items-center">
                <span className="block mr-1 text-sm font-medium sm:text-xl">R$</span>
                <span className="text-5xl font-extrabold sm:text-7xl">{getDezenasValor(planoSelecionado?.valorFinal)}</span>
                <span className="flex flex-col ">
                  <span className="text-base font-extrabold sm:text-xl">,{getCentavosValor(planoSelecionado?.valorFinal)}</span>
                  <span className="-mt-1 text-sm font-medium sm:text-xl">/mes</span>
                </span>
              </span>
            </p>
          </p>
          <ListaBeneficios beneficios={planoSelecionado?.planoDestino?.beneficiosPlano?.ItensBeneficio} />

          {planoSelecionado?.fidelizado === "N" && (
            <span className="text-base mt-2 font-normal leading-3 px-4 py-1 bg-gray-200 rounded-full inline max-w-fit">Plano não fidelizado</span>
          )}
          <p className="text-xs mt-2 font-light leading-3">* Exclusivo para pagamento com cartão de crédito</p>
          <form onSubmit={handleContinuar} className="flex flex-col space-y-4 mt-4">
            <CustomInput
              value={formataTelefone(ani)}
              name="telefoneCliente"
              lable="Número do Telefone"
              type="text"
              disabled
              icon={<PhoneIcon className="icon-input" />}
            />

            <CustomInput
              value={dadosCliente?.cpfCliente}
              onChange={handleChangeInput}
              name="cpfCliente"
              lable="CPF do titular do cartão"
              type="text"
              validFx={validaCPF}
              invalidText={"CPF inválido"}
              formatFx={formataCPF}
              required="Campo obrigatório."
              icon={<UserCircleIcon className="icon-input" />}
            />

            <button type="submit" className="w-full mt-4 py-3 text-xs font-extrabold text-white uppercase rounded-full bg-secondary-tim">
              Continuar
            </button>
          </form>
        </div>
        <div id="iframe-digipay" className={`overflow-y-auto w-full h-screen`}></div>
        {sessaoDigipay && (
          <p className="text-center py-4 -mt-8 text-primary-tim-light">
            <a href="?#" onClick={handleVoltar} title="Voltar" className="text-base">
              <ArrowLongUpIcon className="w-4 inline mr-2" />
              Voltar
            </a>
          </p>
        )}
      </div>

      <Beneficios
        regulamento={true}
        oferta={convertApiAntiga([planoSelecionado])?.[0]}
        exibir={showBeneficios}
        onClose={() => setShowBeneficios(false)}
      />
    </>
  );
}

const ListaBeneficios = ({ beneficios }) => {
  if (!beneficios) {
    return null;
  }

  return (
    <div className="text-sm flex flex-wrap items-center ">
      {beneficios.map((beneficio, index) =>
        beneficio.tipo !== "beneficios" ? null : (
          <>
            <span key={"b_lista_" + index}>{beneficio.item.split("|")?.[0]}</span>
            <svg viewBox="0 0 2 2" className="mx-2 inline h-0.5 w-0.5 fill-current last:hidden" aria-hidden="true">
              <circle cx="1" cy="1" r="1" />
            </svg>
          </>
        )
      )}
    </div>
  );
};
